import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./FacebookOnBoarding.css";
import onboard from "../../images/onboardimage.png";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";
import image5 from "../../images/image5.png";

const FacebookOnBoarding = ({ userID }) => {
  const ConfigID1 = "586221150782928";
  const ConfigID2 = "1164797434986506";
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  // const [currentStep, setCurrentStep] = useState(0);
  const [sdkResponse, setSdkResponse] = useState(null);
  const [waResponse, setWaResponse] = useState(null);
  const [ConfigID, setConfigID] = useState();
  const [loading, setLoading] = useState(false);
  const [isFBReady, setIsFBReady] = useState(false);
  const [Button, setButton] = useState("Next")
  const user = userID;
  //carousel
  const connectionSteps = [
    {
      number: 1,
      title: "Login to Facebook",
      description:
        "Ensure it's the admin account for your meta Business Manager.",
      image: image1,
    },
    {
      number: 2,
      title: "Fill Your Business Information",
      description: "Fill your Business information.",
      image: image2,
    },
    {
      number: 3,
      title: "Create New WhatsApp",
      description: "Create New WhatsApp business account & profile.",
      image: image3,
    },
    {
      number: 4,
      title: "Fill WhatsApp Business Profile info",
      description: "Fill WhatsApp Business profile.",
      image: image4,
    },
    {
      number: 5,
      title: "Verify your Business number on Meta",
      description:
        "Final step to connect your WhatsApp Business account. Review and confirm all settings.",
      image: image5,
    },
  ];
  const images = [image1, image2, image3, image4, image5];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [slideDirection, setSlideDirection] = useState(null);

  const goToNext = () => {
    if (isAnimating) return;

    setIsAnimating(true);
    setSlideDirection("left");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    if (isAnimating) return;

    setIsAnimating(true);
    setSlideDirection("right");
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const getNextIndex = () => {
    return (currentIndex + 1) % images.length;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
      setSlideDirection(null);
    }, 600); // Match this with CSS transition duration

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const getSlideClassName = (index) => {
    if (index === currentIndex) {
      return `carousel-slide active ${
        isAnimating ? `sliding-${slideDirection}` : ""
      }`;
    }
    if (index === getNextIndex()) {
      return "carousel-slide next";
    }
    return "carousel-slide prev";
  };

  //stepper component
  const steps = [
    { title: "Login To Facebook" },
    { title: "Fill Business Information" },
    { title: "Create New Whatsapp" },
    { title: "WhatsApp Bussiness Information" },
    { title: "Verify Busiiness number on Meta" },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const progress = (currentStep / (steps.length - 1)) * 100;

  const getStatus = (index) => {
    if (index < currentStep) {
      return "Complete";
    } else if (index === currentStep) {
      return "In Progress";
    } else {
      return "Pending";
    }
  };
  const updateSteps = (e) => {
    const targetId = e.target.id;
    setCurrentStep((prevStep) =>
      targetId === "next" ? prevStep + 1 : prevStep - 1
    );
   if (targetId==="next"){
    goToNext()
   }else{
    goToPrev()
   }
  };

  const registerMobileNumber = async () => {
    const url =
      "https://api.anlook.com/api/Account/v1/facebookRegisterMobileNumber";

    const payload = {
      userid: userID,
    };

    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response Data:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error occurred while registering:",
        error.response || error
      );
    }
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "801281555533147",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v21.0",
      });
      setIsFBReady(true); // Indicate that FB is ready to use
    };

    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleConnectNow = () => {
    if (ConfigID) {
      setIsCarouselOpen(true);
    } else {
      toast.info("Please Select one of the given options");
    }
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) =>
      Math.min(prevStep + 1, connectionSteps.length - 1)
    );
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const pushResponseCall = async (responseData) => {
    if (!responseData) return;

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const response = await axios.post(
        "https://apiuat.anlook.com/api/Account/v1/facebookSignupOnboardingWebhook",
        responseData,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        registerMobileNumber();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // setWaResponse({ ...data, userid: user });
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            pushResponseCall({ ...data, userid: userID });
            console.log(
              "Phone number ID ",
              phone_number_id,
              " WhatsApp business account ID ",
              waba_id
            );
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            // setWaResponse({ ...data, userid: user });
            console.warn("Cancel at ", current_step);
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }

        document.getElementById("session-info-response").textContent =
          JSON.stringify(data, null, 2);
      } catch {
        console.log("Non JSON Responses", event.data);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      pushResponseCall({ ...response, userid: userID });
      // setSdkResponse(response);
      toast.success("Successfully connected to Facebook!");
    } else {
      toast.error("Facebook login failed. Please try again.");
    }
  };

  const launchWhatsAppSignup = () => {
    if (isFBReady && window.FB) {
      window.FB.login(fbLoginCallback, {
        // config_id: "565106129591059", esConfig
        // config_id: "1035022388668066", //esConfig 2
        // config_id: "1164797434986506", //esConfig 3

        config_id: ConfigID, //esConfig 3

        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "2",
        },
      });
    } else {
      console.error("Facebook SDK is not loaded yet.");
    }
  };
//   useEffect(() => {
//  if(currentStep === 4 ){
//   setButton("Connect Now")
//  }
//   }, [currentStep]);

  return (
    <div className="facebook-onboarding-container">
      <ToastContainer />
      {!isCarouselOpen ? (
        <div className="onboarding-initial-screen d-flex">
          <img src={onboard ? onboard : "cool image"} alt="Onboarding" />
          <div className="onboarding-test-first">
            <div className="onboarding-header">
              <div className="d-flex">
                <h2>To Experience Messaging, Please connect you </h2>{" "}
                <h2 className="onboarding-header" style={{ color: "green" }}>
                  {" "}
                  WhatsApp{" "}
                </h2>{" "}
              </div>
              <h2>number.</h2>

              <p>
                You can connect your WhatsApp Number to start messaging contacts
                or receive messages from them.
              </p>
            </div>
            <div className="onboarding-prerequisites">
              <h3>Few things to know before you connect</h3>
              <ul>
                <li className="">
                  → You must have Admin access to your Meta Business Manager
                </li>
                <li className="">
                  → You must have a phone number that does not have a WhatsApp
                  Account
                </li>
                <li>
                  • Deregister number that has been with WhatsApp Business before.
                </li>
              </ul>
            </div>
            <hr />

            <div
              className="onboarding-actions"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <button className="connect-now-btn">
                {loading ? "Connecting..." : "Connect Now"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-evenly">
          <div className="onboarding-carousel-screen mt-4">
            <div className="mx-4 mt-4">
              <h3>You are almost there..!</h3>
              <p>
              Ensure it's the admin account for your meta Business Manager.
              </p>
              <div>
                <div
                  className="stepper-container1 align-items-start "
                  style={{ height: "100%" }}
                >
                  <div className="progress-bar1">
                    <div className="deactivated-bar1"></div>
                    <div
                      className="activated-bar1"
                      style={{ height: `${progress}%` }}
                    ></div>
                  </div>
                  {steps.map((step, index) => (
                    <div className="step2" key={index}>
                      <div
                        className={`step-number1 ${
                          index < currentStep
                            ? "completed1"
                            : index === currentStep
                            ? "active1"
                            : ""
                        }`}
                      >
                        {index + 1}
                      </div>
                      <div className="step-details1">
                        <div
                          className={`step-title1 ${
                            index <= currentStep ? "active1" : ""
                          }`}
                        >
                          {step.title}
                        </div>
                        <div
                          className={`step-status1 ${
                            index <= currentStep ? "active1" : ""
                          }`}
                          style={{
                            color: `${
                              getStatus(index) === "Complete"
                                ? "green"
                                : getStatus(index) === "In Progress"
                                ? "#4b81f4"
                                : "black"
                            }`,
                          }}
                        >
                          {connectionSteps[index].description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="p-3 px-4 d-flex justify-content-evenly">
              <button
                className="connect-previous-btn"
                onClick={updateSteps}
                disabled={currentStep === 0}
              >
                Previous
              </button>
              <button
                className="connect-next-btn"
                id="next"
                onClick={currentStep === 4? launchWhatsAppSignup:updateSteps}
              >
               {currentStep === 4? "Connect Now":"Next"}
              </button>
            </div>
          </div>
          <div className="onboarding-carousel-screen1 mt-4">
            <div className="mx-4 mt-4">
              {" "}
              <h5>{connectionSteps[currentStep].title}</h5>
              <p>
              {connectionSteps[currentStep].description}
              </p>
            </div>

            <div className="carousel-container">
              <div className="carousel-track">
                {images.map((image, index) => (
                  <div key={index} className={getSlideClassName(index)}>
                    <img src={image} alt={`Slide ${index}`} draggable="false" />
                  </div>
                ))}

                {/* <button
                  className="carousel-button prev-button"
                  onClick={goToPrev}
                  disabled={isAnimating}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <button
                  className="carousel-button next-button"
                  onClick={goToNext}
                  disabled={isAnimating}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button> */}

                <div className="carousel-dots">
                  {images.map((_, index) => (
                    <button
                      key={index}
                      className={`carousel-dot ${
                        index === currentIndex ? "active" : ""
                      }`}
                      onClick={() => {
                        if (!isAnimating) {
                          setIsAnimating(true);
                          setSlideDirection(
                            index > currentIndex ? "left" : "right"
                          );
                          setCurrentIndex(index);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* <div className="carousel-footer">
              <button
                className="connect-facebook-btn"
                onClick={launchWhatsAppSignup}
              >
                Connect to Facebook
              </button>
            </div> */}
          </div>
        </div>
      )}
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {" "}
                Select messaging only or messaging with a catalogue to fit your
                business needs.
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              {" "}
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onClick={() => setConfigID(ConfigID1)}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  <b> WhatsApp Messaging</b>
                </label>
                <p className="onboarding-prerequisites">
                  Communicate with your customers seamlessly via chat for
                  support and engagement.
                </p>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onClick={() => setConfigID(ConfigID2)}
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  <b>WhatsApp Messaging with Catelogue</b>
                </label>
                <p className="onboarding-prerequisites">
                  Showcase your products or services alongside messaging for a
                  complete shopping experience.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleConnectNow}
                data-bs-dismiss="modal"
              >
                Connect Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookOnBoarding;
