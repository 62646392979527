import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCheck,
  faTimes,
  faUsers,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";

const Grouping = ({ userID, fetchData, contacts, DeveloperID , keyId }) => {
;
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupName, setGroupName] = useState("");

  // Transform contact object to selected format
  const transformContactFormat = (contact) => ({
    mobile: contact.contact_number,
    email: contact.contact_email,
    name: contact.contact_name,
    gender: contact.gender,
    tags: contact.tags,
  });

  // Filter contacts based on search term
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.contact_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.contact_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.contact_number.includes(searchTerm) ||
      contact.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleContactSelection = (contact) => {
    if (selectedContacts.find((c) => c.mobile === contact.contact_number)) {
      setSelectedContacts(
        selectedContacts.filter((c) => c.mobile !== contact.contact_number)
      );
    } else {
      setSelectedContacts([
        ...selectedContacts,
        transformContactFormat(contact),
      ]);
    }
  };

  const handleSave = () => {
    if (!groupName.trim()) {
      toast.error("Please enter a group name");
      return;
    }
    if (selectedContacts.length === 0) {
      toast.error("Please select at least one contact");
      return;
    }

    const groupData = {
      name: groupName,
      contacts: selectedContacts,
    };

    CreateNewGroup();
  };
  const CreateNewGroup = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      const raw = JSON.stringify({
        audienceGroupName: groupName,
        audience: selectedContacts,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://openapi.anlook.com/api/Campaign/v1/createAudienceGroup`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage + "🚀");
        setSelectedContacts([]);
        setGroupName("");
      } else {
        toast.error(result.responseMessage);
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to launch campaign");
    } finally {
    }
  };
  return (
    <>
      <div className=" d-flex w-100">
        <div className="row bg-white  border-15 w-100">
          <div className="p-4">
            <div className="font-bold py-2">Create Group</div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Group Name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>

            <div className="row">
              {/* Left side - Contact Selection */}
              <div className="col-md-6">
                <div className="h-100">
                  <Card.Header className="bg-primary text-white">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faUsers} className="me-2" />
                      <h5 className="m-2">Available Contacts</h5>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="mb-3 position-relative">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="position-absolute top-50 translate-middle-y ms-3 text-muted"
                      />
                      <input
                        type="text"
                        className="form-control ps-5"
                        placeholder="Search contacts, tags..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div
                      className="contact-list"
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      {filteredContacts.map((contact) => (
                        <div
                          key={contact.id}
                          className="d-flex align-items-center p-2 border-bottom cursor-pointer hover:bg-gray-100"
                          onClick={() => handleContactSelection(contact)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="me-3">
                            {selectedContacts.find(
                              (c) => c.mobile === contact.contact_number
                            ) ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-success"
                              />
                            ) : (
                              <div
                                className="border rounded-circle"
                                style={{ width: "16px", height: "16px" }}
                              />
                            )}
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-bold">
                              {contact.contact_name}
                            </div>
                            <div className="text-muted small">
                              {contact.contact_email}
                            </div>
                            <div className="mt-1">
                              {contact.tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="badge bg-light text-dark me-1"
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className="me-1"
                                    size="xs"
                                  />
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </div>
              </div>

              {/* Right side - Selected Contacts */}
              <div className="col-md-6">
                <div className="h-100">
                  <Card.Header className="bg-success text-white">
                    <h5 className="m-2">
                      Selected Contacts ({selectedContacts.length})
                    </h5>
                  </Card.Header>
                  <Card.Body>
                    <div
                      className="selected-contacts"
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      {selectedContacts.map((contact) => (
                        <div
                          key={contact.mobile}
                          className="d-flex align-items-center justify-content-between p-2 border-bottom"
                        >
                          <div className="flex-grow-1">
                            <div className="fw-bold">{contact.name}</div>
                            <div className="text-muted small">
                              {contact.email}
                            </div>
                            <div className="mt-1">
                              {contact.tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="badge bg-light text-dark me-1"
                                >
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    className="me-1"
                                    size="xs"
                                  />
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                          <button
                            className="btn btn-link text-danger"
                            onClick={() =>
                              handleContactSelection({
                                contact_number: contact.mobile,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      ))}
                      {selectedContacts.length === 0 && (
                        <div className="text-center text-muted p-4">
                          No contacts selected
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button
                className="btn btn-primary btn-lg"
                onClick={handleSave}
                disabled={!groupName.trim() || selectedContacts.length === 0}
              >
                Save Group
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grouping;
