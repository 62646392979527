import React, { useState, useEffect, useDebugValue, useRef } from "react";
import Swal from "sweetalert2";
import trash from "../../images/trash.svg";
import { Modal } from "react-bootstrap";
import cut from "../../images/close-circle.svg";
import NoIndex from "..//NoIndex";
import ContectBook from "./Sms_add";

import axios from "axios";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat_BG from "../../images/chat_BG.png";
import wpImage from "../../images/attherate.png";
import sendImage from "../../images/Send.svg";
import Grouping from "./SmsGrouping";
import AudienceGroupView from "./SmsGroupView";

const SmsChats = ({ userID, DeveloperID , keyId }) => {
  const messagesEndRef = useRef(null);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatShow, setChatShow] = useState(true);
  const [allContectShow, setAllContectShow] = useState(true);
  const [activeButton, setActiveButton] = useState("2");
  const [mobileNumberSelected, setMobileNumberSelected] = useState("");
  const [message, setMessage] = useState("");
  const [Group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [Groups, setGroups] = useState([]);
  const [CreateGroup, setCreateGroup] = useState(false);
  const [GroupsView, setGroupsView] = useState(false);
  const [groupName, setGroupName] = useState("");

  useEffect(() => {
    scrollToBottom();
  }, [chats]); // Dependency on chats ensures that the effect runs whenever chats change

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }, 0); // Using a slight delay to ensure messages are fully rendered before scrolling
  };

  const DeleteGroup = async (group) => {
    Swal.fire({
      title: "Unblock this User?",
      text: "You Can block them later too!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(async (result) => {
      // Make this function async
      if (result.isConfirmed) {
        try {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            "Basic " + btoa(`${DeveloperID}:${keyId}`)
          );

          const raw = JSON.stringify({
            audienceGroupName: group,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            `https://openapi.anlook.com/api/Campaign/v1/deleteAudienceGroup`,
            requestOptions
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          if (result.responseCode == "00") {
            toast.success(result.responseMessage + "🚀");
          } else {
            toast.error(result.responseMessage);
          }

          // Handle the response data if needed
          console.log(result);
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to Edit Group");
        } finally {
          setTimeout(() => {
            fetchGroups();
            fetchData();
          }, 3000);
        }
      }
    });
  };
  const DeleteAudience = async (id) => {
    Swal.fire({
      title: "Unblock this User?",
      text: "You Can block them later too!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(async (result) => {
      // Make this function async
      if (result.isConfirmed) {
        try {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            "Basic " + btoa(`${DeveloperID}:${keyId}`)
          );

          const raw = JSON.stringify({
            audienceId: id,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            `https://openapi.anlook.com/api/Campaign/v1/deleteAudience`,
            requestOptions
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          if (result.responseCode == "00") {
            toast.success(result.responseMessage + "🚀");
          } else {
            toast.error(result.responseMessage);
          }

          // Handle the response data if needed
          console.log(result);
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to Edit Group");
        } finally {
          setTimeout(() => {
            fetchData();
          }, 3000);
        }
      }
    });
  };

  // Handler to select a contact
  const handleSelectContact = (id) => {
    setSelectedContactId(id);
  };

  const handleSendMessage = () => {
    if (!messageText.trim()) return;
    const newMessage = {
      fromMe: true,
      text: messageText,
      timestamp: new Date().toLocaleTimeString(),
    };
    const updatedMessages = {
      ...messages,
      [selectedContactId]: [...(messages[selectedContactId] || []), newMessage],
    };

    setMessages(updatedMessages);
    setMessageText("");
  };

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      // const raw = JSON.stringify({
      //   userid: userID,
      // });

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://openapi.anlook.com/api/Campaign/v1/getAudience",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setContacts(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchGroups = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://openapi.anlook.com/api/Campaign/v1/getAudienceGroups",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setGroups(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGroups();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    tags: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        tags: value.split(",").map((tag) => tag.trim()),
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  const handleEditContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        userid: userID,
        contactNumber: contactInfo.contactNumber,
        contactEmail: contactInfo.contactEmail,
        contactName: contactInfo.contactName,
        gender: contactInfo.gender,
        tags: contactInfo.tags,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Campaign/editContact",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        setShowModal(false);
        fetchData();
      } else {
        toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };

  const deleteContactAPI = async (number) => {
    try {
      const requestBody = {
        userid: userID,
        contactNumber: number,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        const response = await axios.post(
          "https://api.anlook.com/api/Campaign/deleteContact",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          // Contact deleted successfully
          toast.success(response.data.responseMessage);
          fetchData();
        } else {
          // Failed to delete contact
          toast.error(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.error(error);
      // Handle error here
      Swal.fire("Error!", "An error occurred while deleting contact.", "error");
    }
  };

  const setSelectedContact = (selectedContactId) => {
    console.log("hii");
    console.log(selectedContactId);

    setShowModal(true);
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        tags: selectedContact.tags,
      });

      console.log(contactInfo);
    }
  };
  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <div style={{ display: "flex", height: "100vh" }}>
        <div className="contactsList  ">
          <div className="  p-4 ">
            <div className=" row  d-flex justify-content-between border-bottom-chat ">
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "2" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(true);
                    setChatShow(false);
                    setActiveButton("2");
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                    setGroupsView(false);
                  }}
                >
                  Audience
                </button>
              </div>
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "3" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setSelectedContactId(null);
                    setActiveButton("3");
                    setAllContectShow(false);
                    setGroupsView(true);
                  }}
                >
                  Groups
                </button>
              </div>
            </div>
          </div>

          {allContectShow && (
            <>
              <div className="d-flex justify-content-between">
                {" "}
                <div className=" px-4 text3 font-bold-400">All SMS</div>
                <div>
                  <p
                    onClick={() => setCreateGroup(CreateGroup ? false : true)}
                    style={{ textDecoration: "underline" }}
                    className="text-primary me-3 cursor-pointer"
                  >
                    Create Group
                  </p>
                </div>
              </div>

              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    backgroundColor:
                      selectedContactId === contact.id
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                  className="ms-2"
                >
                  {/* Contact Name */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-flex justify-content-start"
                      onClick={() => {
                        setMobileNumberSelected(contact.contact_number);
                        setSelectedContactId(contact.id);
                        // setChats([]);
                      }}
                    >
                      <img
                        src={wpImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                        className="  p-1"
                      />
                      <div>
                        <div>{contact.contact_name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            marginTop: "5px",
                          }}
                        >
                          {contact.contact_number}
                          {/* {
                      messages[contact.id]?.[messages[contact.id].length - 1]
                        ?.text
                    } */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          DeleteAudience(contact.id);
                        }}
                        className=" p-2 cursor-pointer text-center  text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <img src={trash} alt="Logo" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {!allContectShow && (
            <>
              <div className="d-flex justify-content-between">
                {" "}
                <div className=" px-4 text3 font-bold-400">All Groups</div>
                <div>
                  <p
                    onClick={() => setCreateGroup(CreateGroup ? false : true)}
                    style={{ textDecoration: "underline" }}
                    className="text-primary me-3 cursor-pointer"
                  >
                    Create Group
                  </p>
                </div>
              </div>

              {Groups?.map((contact) => (
                <div
                  key={contact.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    backgroundColor:
                      selectedContactId === contact.id
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                  className="ms-2"
                >
                  {/* Contact Name */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-flex justify-content-start"
                      onClick={() => {
                        setGroup(contact);
                        // setChats([]);
                      }}
                    >
                      <img
                        src={wpImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                        className="  p-1"
                      />
                      <div>
                        <div>{contact.group_name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            marginTop: "5px",
                          }}
                        >
                          {contact.audience.length} Group Member
                          {/* {
                      messages[contact.id]?.[messages[contact.id].length - 1]
                        ?.text
                    } */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          DeleteGroup(contact.group_name);
                        }}
                        className=" p-2 cursor-pointer text-center  text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <img src={trash} alt="Logo" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div
          className="bg-white"
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          {loading ? (
            <div
              className=" m-5 p-5 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              {/* <div className="loader "></div> */}
            </div>
          ) : (
            <>
              <div
                className="p-4 bg-white contactsList_chat"
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "0px",
                  backgroundImage: `url(${Chat_BG})`,
                  backgroundRepeat: "repeat",
                  backgroundPosition: "center",

                  backgroundSize: "30%",
                }}
              >
                <>
                  {GroupsView && Group ? (
                    <AudienceGroupView groupData={Group} />
                  ) : (
                    <></>
                  )}
                  {!GroupsView ? <ContectBook /> : ""}

                  {CreateGroup && !GroupsView ? (
                    <Grouping contacts={contacts} />
                  ) : (
                    ""
                  )}
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SmsChats;
