import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

import "./Developer.css";
import CreateCredentialModal from "./CreateCredentialModal";
import { toast, ToastContainer } from "react-toastify";
import { FaPen } from "react-icons/fa6";

function Triggers() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAPIReferenceDropdownOpen, setAPIReferenceDropdownOpen] =
    useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState({
    key: "sandbox",
    value: "Sandbox",
  });
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setAPIData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const [triggerName, setTriggerName]= useState('');
  const [triggerEmailData, setTriggerEmailData] = useState([
    {
      channel_id: "",
      channel_name: "",
      created_on: "",
      fk_app_id: "",
      fk_user_id: "",
      id: "",
      template_category: "",
      template_content: "",
      template_header: "",
      template_name: "",
      template_provider_id: "",
      template_status: "",
      template_type: "",
      updated_on: "",
    },
  ]);
  const [triggerSMSData, setTriggerSMSData] = useState(null);

  console.log(triggerSMSData, "data in SMS state");

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedCategory("All"); // Reset category when type changes
    setIsDropdownOpen(false);
  };

  const DeveloperID = "E0c9W1L7-TQa0HT-4033776Huel9-431Tg0-758OvR1p";
  const keyId = "LZ7K5K61-31723Q-9PnmT861x9SA-2a75G2-M60t46Tn";

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://openapi.anlook.com/api/Trigger/v1/getTriggers",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setAPIData(result.data);
        console.log(result.data, "data fetched>>>>");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function handleEmailTriggerDetailModal(item) {
    setTriggerName(item?.trigger_name);
    const FilteredArrayOfObj = item?.templates?.filter(
      (obj) => obj.channel_name === "email"
    );
    setTriggerEmailData(FilteredArrayOfObj);
  }

  function handleSMSTriggerDetailModal(item) {
    setTriggerName(item?.trigger_name);
    const FilteredArrayOfObj = item?.templates?.filter(
      (obj) => obj.channel_name === "SMS"
    );
    setTriggerSMSData(FilteredArrayOfObj);
  }

  return (
    <div style={{ padding: "5rem" }}>
      <ToastContainer />

      {/* text section */}
      <div className="d-flex pt-1">
        <div className="" style={{ width: "80%" }}>
          <h1 className="CreateCampaign">Triggers</h1>
        </div>
        <div></div>
      </div>

      {/* Table section */}
      <div className="" style={{ paddingTop: "2rem" }}>
        <table
          className="table table-hover "
          style={{
            border: "1px solid #dddddd",
            borderRadius: "10px",
          }}
        >
          <thead style={{ borderRadius: "10px" }}>
            <tr className="text-start greenColour  py-4 ">
              <th className="pb-3" style={{ color: "#000" }}>
                Triggers
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                Email
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                SMS
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                Actions
              </th>
            </tr>
          </thead>
          {loading ? (
            <div
              className="d-flex justify-content-center d-flex text-center align-items-center p-4"
              style={{ width: "300%" }}
            >
              <div className="atext-center loader"></div>
            </div>
          ) : (
            <tbody>
              {apiData?.map((item, index) => (
                <tr className="text-start">
                  <td className="pt-4">
                    {item?.trigger_name || "triggername"}
                  </td>

                  {/* Email Checkbox */}
                  <td className="pt-4">
                    <input
                      type="checkbox"
                      checked={item?.templates?.find(
                        (obj) => obj.channel_name === "email"
                      )}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      disabled={
                        !item?.templates?.find(
                          (obj) => obj.channel_name === "email"
                        )
                      }
                      onClick={() => handleEmailTriggerDetailModal(item)}
                    />
                  </td>

                  {/* SMS Checkbox */}
                  <td className="pt-4">
                    <input
                      type="checkbox"
                      checked={item?.templates?.find(
                        (obj) => obj.channel_name === "SMS"
                      )}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      disabled={
                        !item?.templates?.find(
                          (obj) => obj.channel_name === "SMS"
                        )
                      }
                      onClick={() => handleSMSTriggerDetailModal(item)}
                    />
                  </td>
                  <td className="pt-4 d-flex gap-4">
                    <div>
                      <FaPen />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <>
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg d-flex justify-content-center" style={{maxWidth: '90%'}}>
            <div class="modal-content">
                            <div class="modal-header">
                              <h1
                                class="modal-title fs-5 text-success"
                                id="exampleModalLabel"
                              >
                              {triggerName}
                              </h1>

                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body d-flex gap-3"
                            style={{
                              height: "80vh",
                            }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  border: "1px solid lightgrey",
                                  borderRadius: "5px",
                                
                                }}
                                className="d-flex justify-content-start flex-column p-4 gap-2"
                              >
                                <strong >Email</strong>
                                <div style={{overflow: 'scroll'}} className="gap-2 d-flex flex-column hide-scrollbar">
                                  {triggerEmailData?.map((item) => (
                                    <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '1rem', fontSize: '14px'}}>
                                      <p>Channel ID: {item?.channel_id || ""}</p>
                <p>Channel Name: {item?.channel_name || ""}</p>
                <p>Created On: {item?.created_on || ""}</p>
                <p>App ID: {item?.fk_app_id || ""}</p>
                <p>User ID: {item?.fk_user_id || ""}</p>
                <p>Template Category: {item?.template_category || ""}</p>
                <p>Template Content: {item?.template_content || ""}</p>
                <p>Template Header: {item?.template_header || ""}</p>
                <p>Template Name: {item?.template_name || ""}</p>
                <p>Template Status: {item?.template_status || ""}</p>
                <p>Template Type: {item?.template_type || ""}</p>
                <p>Updated On: {item?.updated_on || ""}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  border: "1px solid lightgrey",
                                  borderRadius: "5px",
                                }}
                                className="d-flex justify-content-start flex-column p-4 gap-2"
                              >
                                <strong>SMS</strong>
                                <div style={{overflow: 'scroll'}} className="gap-4 d-flex flex-column hide-scrollbar">
                                  {triggerSMSData?.map((item) => (
                                    <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '1rem', fontSize: '14px'}}>
                                      <p>Channel ID: {item?.channel_id || ""}</p>
                <p>Channel Name: {item?.channel_name || ""}</p>
                <p>Created On: {item?.created_on || ""}</p>
                <p>App ID: {item?.fk_app_id || ""}</p>
                <p>User ID: {item?.fk_user_id || ""}</p>
                <p>Template Category: {item?.template_category || ""}</p>
                <p>Template Content: {item?.template_content || ""}</p>
                <p>Template Header: {item?.template_header || ""}</p>
                <p>Template Name: {item?.template_name || ""}</p>
                <p>Template Status: {item?.template_status || ""}</p>
                <p>Template Type: {item?.template_type || ""}</p>
                <p>Updated On: {item?.updated_on || ""}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Triggers;
