import React, { useState, useEffect, useDebugValue, useRef } from "react";
import Swal from "sweetalert2";
import trash from "../../images/trash.svg";
import { Modal } from "react-bootstrap";
import cut from "../../images/close-circle.svg";
import NoIndex from "..//NoIndex";
import ContectBook from "./Contact_Add";
import axios from "axios";
import food from "../../images/Pexels Photo by Malidate Van.svg";
import Cart from "../../images/Cart.svg";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Chat_BG from "../../images/chat_BG.png";
import wpImage from "../../images/Whatsapp.svg";
import Edit from "../../images/Edit.svg";
import Tag from "../../images/Tag-2.svg";
import sendImage from "../../images/Send.svg";

const Chats = ({ userID }) => {
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [People, setPeople] = useState([]);
  const [SelectedPeople, setSelectedPeople] = useState("");
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [selectedContactId, setSelectedContactId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatShow, setChatShow] = useState(true);
  const [allContectShow, setAllContectShow] = useState(false);
  const [activeButton, setActiveButton] = useState("1");
  const [mobileNumberSelected, setMobileNumberSelected] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [Labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [LoopIt, setLoopIt] = useState([]);
  const handleSelectChange = (event) => {
    setSelectedLabel(event.target.value);
  };
  // useEffect(() => {
  //   scrollToBottom();
  // }, [LoopIt]); 
  // useEffect(() => {
  // setLoopIt([])
  // }, [selectedContactId, mobileNumberSelected])
  

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }, 0); // Using a slight delay to ensure messages are fully rendered before scrolling
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the message to remove unnecessary whitespace
    const trimmedMessage = message.trim();

    // Validate the message
    if (!trimmedMessage) {
      console.error("Message cannot be empty or contain only spaces.");
      return;
    }

    // Validate other required fields
    if (!userID) {
      console.error("User ID is missing.");
      return;
    }

    if (!mobileNumberSelected) {
      console.error("Mobile number is missing.");
      alert("Please select a valid contact number.");
      return;
    }

    setLoading(true);

    // Prepare headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );

    // Prepare the request body
    const raw = JSON.stringify({
      userid: userID,
      secret: "NA",
      message: trimmedMessage,
      contact: mobileNumberSelected,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      // Send the API request
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/sendInstantMessage",
        requestOptions
      );
      const result = await response.text();
      console.log("API Response:", result);

      // Reset message and fetch updated chat
      setMessage("");
      fetchChats();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send the message. Please try again."); // Optionally show an error alert
    } finally {
      setLoading(false);
    }
  };
  const fetchChats = async (e) => {
    // setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );
    const raw = JSON.stringify({
      userid: userID,
      secret: "string",
      contact: mobileNumberSelected,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/getWhatsappConversation",
        requestOptions
      );
      const result = await response.json();
      if (result.responseCode === "00") {
        if (result.data !== null) {
          setChats(result.data);
          if(LoopIt.length===0){
          setLoopIt(result.data)
          }
        }
      } else {
        console.error(
          "Error fetching previous messages:",
          result.responseMessage
        );
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChats();
  }, [selectedContactId, mobileNumberSelected]);
  useEffect(() => {
    const interval = setInterval(fetchChats, 3000); // Poll every 10 seconds
    return () => clearInterval(interval);
  }, [mobileNumberSelected, userID]);

  // Handler to select a contact
  const handleSelectContact = (id) => {
    setSelectedContactId(id);
  };

  const handleSendMessage = () => {
    if (!messageText.trim()) return;
    const newMessage = {
      fromMe: true,
      text: messageText,
      timestamp: new Date().toLocaleTimeString(),
    };
    const updatedMessages = {
      ...messages,
      [selectedContactId]: [...(messages[selectedContactId] || []), newMessage],
    };

    setMessages(updatedMessages);
    setMessageText("");
  };

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.anlook.com/api/Campaign/getContacts",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setContacts(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchLabel = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/v1/getChatLabels",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setLabels(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLabel();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });
  const [contactInfo1, setContactInfo1] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });
  const [contactInfo2, setContactInfo2] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        tags: value.split(",").map((tag) => tag.trim()),
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };
  const getAllUserList = async (e) => {
    try {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/User/getAllUserList`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setPeople(data?.response);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    getAllUserList();
  }, []);

  const handleEditContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        userid: userID,
        contactNumber: contactInfo.contactNumber,
        contactEmail: contactInfo.contactEmail,
        contactName: contactInfo.contactName,
        gender: contactInfo.gender,
        tags: contactInfo.tags,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Campaign/editContact",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        setShowModal(false);
        fetchData();
      } else {
        toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };
  const AddTagContactSubmit = async (e) => {
    // e.preventDefault();
    console.log(selectedLabel);

    // Use find instead of map to get the matching label
    const Label = Labels.find((item) => selectedLabel === item.title);

    // if (!Label) {
    //   toast.error("Label not found. Please select a valid label.");
    //   return; // Exit if no matching label is found
    // }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        contactId: contactInfo1.id,
        contactNumber: contactInfo1.contactNumber,
        userid: userID,
        label: Label, // Ensure the label is correctly assigned
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/v1/assignLabelToChat",
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setSelectedLabel("Add Label");
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });

        fetchData();
      } else {
        // toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the contact.");
    }
  };
  const AssignChat = async (e) => {
    // e.preventDefault();
    console.log(selectedLabel);

    // Use find instead of map to get the matching label
    const Label = Labels.find((item) => selectedLabel === item.title);

    // if (!Label) {
    //   toast.error("Label not found. Please select a valid label.");
    //   return; // Exit if no matching label is found
    // }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        contactId: contactInfo2.id,
        teamMemberId: SelectedPeople,
        userid: userID,
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/v1/assignTeamMemberToChat",
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setSelectedLabel("");
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });

        fetchData();
      } else {
        // toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the contact.");
    }
  };
  useEffect(() => {
    AssignChat();
  }, [SelectedPeople]);

  useEffect(() => {
    AddTagContactSubmit();
  }, [selectedLabel]);

  const deleteContactAPI = async (number) => {
    try {
      const requestBody = {
        userid: userID,
        contactNumber: number,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        const response = await axios.post(
          "https://api.anlook.com/api/Campaign/deleteContact",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          // Contact deleted successfully
          toast.success(response.data.responseMessage);
          fetchData();
        } else {
          // Failed to delete contact
          toast.error(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.error(error);
      // Handle error here
      Swal.fire("Error!", "An error occurred while deleting contact.", "error");
    }
  };

  const setSelectedContact = (selectedContactId) => {
    console.log("hii");
    console.log(selectedContactId);

    setShowModal(true);
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        tags: selectedContact.tags,
      });

      console.log(contactInfo);
    }
  };
  const setSelectedContact1 = (selectedContactId) => {
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo1({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        id: selectedContact.id,
        tags: selectedContact.tags,
      });

      console.log(contactInfo);
    }
  };
  const setSelectedContact2 = (selectedContactId) => {
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo2({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        id: selectedContact.id,
        tags: selectedContact.tags,
      });

      console.log(contactInfo);
    }
  };

  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <div style={{ display: "flex", height: "100vh" }}>
        <div className="contactsList  ">
          <div className="py-4">
            <div className=" row  d-flex justify-content-between border-bottom-chat ">
              <div className=" col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "1" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(false);
                    setChatShow(true);
                    setActiveButton("1");
                    setChats([]);
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                  }}
                >
                  Chats
                </button>
              </div>
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "2" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(true);
                    setChatShow(false);
                    setActiveButton("2");
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                  }}
                >
                  Contacts
                </button>
              </div>
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "3" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setSelectedContactId(null);
                    setActiveButton("3");
                  }}
                >
                  + Add
                </button>
              </div>
            </div>
          </div>

          {chatShow && (
            <>
              <div className=" px-4  text3 font-bold-400">Recent Chats</div>
              {contacts
                .filter((contact) => contact.last_conversation !== null)
                .map((contact) => (
                  <div
                    key={contact.id}
                    //  onClick={() => handleSelectContact(contact.id)}
                    onClick={() => {
                      setMobileNumberSelected(contact.contact_number);
                      setSelectedContactId(contact.id);
                      setChats([]);
                      fetchData();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // padding: "10px 20px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                      backgroundColor:
                        selectedContactId === contact.id
                          ? "#f0f0f0"
                          : "transparent",
                    }}
                    className="ms-1"
                  >
                    {/* Contact Name */}
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={wpImage}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                          className="ms-2  p-1"
                        />

                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="my-2 ">{contact.contact_name}</div>
                            {contact?.assigned_user !== "NA" ? (
                              <div
                                className="badge badge-pill shadow ms-3 pb-0"
                                style={{
                                  backgroundColor: `grey`,
                                  color: "#fff",
                                  zIndex: "1",
                                  position: "relative",
                                  height: "2rem",
                                  borderRadius: "0rem 0rem 1rem 1rem",
                                }}
                              >
                                {contact.assigned_user}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#666",
                              margin: "5px 0px",
                            }}
                          >
                            {contact.last_conversation.slice(0, 35)}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        {contact.unread_count !== "0" && (
                          <span className="unread-badge ">
                            {contact.unread_count}
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-end align-item-start">
                        <div
                          className="p-2 m-0"
                          style={{
                            display: `${
                              contact.label.title ? "block" : "none"
                            }`,
                            backgroundColor: `${contact.label.color}`,
                            color: "#fff",

                            borderRadius: "1rem 0rem 0rem 1rem",
                          }}
                        >
                          {contact.label.title}
                        </div>
                      </div>

                      {/* <img className="w-10" src={contact.label.icon} alt="" /> */}
                    </div>
                  </div>
                ))}
            </>
          )}

          {allContectShow && (
            <>
              <div className=" px-4 text3 font-bold-400">All Contacts</div>
              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    backgroundColor:
                      selectedContactId === contact.id
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                  className="ms-2"
                >
                  {/* Contact Name */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-flex justify-content-start"
                      onClick={() => {
                        setMobileNumberSelected(contact.contact_number);
                        setSelectedContactId(contact.id);
                        setChats([]);
                      }}
                    >
                      <img
                        src={wpImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                        className="  p-1"
                      />
                      <div>
                        <div>{contact.contact_name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            marginTop: "5px",
                          }}
                        >
                          {contact.contact_number}
                          {/* {
                      messages[contact.id]?.[messages[contact.id].length - 1]
                        ?.text
                    } */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          deleteContactAPI(contact.contact_number);
                        }}
                        className=" p-2 cursor-pointer text-center  text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <img src={trash} alt="Logo" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div
          className="bg-white"
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          {loading ? (
            <div
              className=" m-5 p-5 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <div className="loader "></div>
            </div>
          ) : (
            <>
              {selectedContactId && (
                <div className="p-4 bg-white">
                  <div
                    style={{ position: "sticky", top: 0 }}
                    className=" d-flex justify-content-between font2 text-white text-start border-radius-20 p-2 px-3 font3 font-bold-400 bg-green-filter"
                  >
                    <div className="">
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_name
                      }
                      {" - "}
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_number
                      }
                    </div>
                    <div>
                      <div className="d-flex">
                        <select
                          className="form-select me-2"
                          aria-label="Default select example"
                          value={SelectedPeople}
                          onChange={(e) => setSelectedPeople(e.target.value)}
                          onClick={() => setSelectedContact2(selectedContactId)}
                        >
                          <option>Assign Chat</option>
                          {People.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.user_name}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-select me-2"
                          aria-label="Default select example"
                          value={selectedLabel}
                          onChange={handleSelectChange}
                          onClick={() => setSelectedContact1(selectedContactId)}
                        >
                          <option>Add Label</option>
                          {Labels.map((item, index) => (
                            <option key={index} value={item.title}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        <button
                          //  onClick={() => setShowModal(true)}
                          onClick={() => setSelectedContact(selectedContactId)}
                          className=" cursor-pointer text-centersss bg-white text-white me-1"
                          style={{
                            border: "0px solid #000",
                            borderRadius: "0.5rem",
                          }}
                        >
                          <img
                            style={{ width: "2rem" }}
                            src={Edit}
                            alt="Logo"
                          />

                          {/* Edit */}
                        </button>
                      </div>

                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleEditContactSubmit}>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactName"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactName"
                                name="contactName"
                                value={contactInfo.contactName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactNumber"
                              >
                                Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactNumber"
                                name="contactNumber"
                                value={contactInfo.contactNumber}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactEmail"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="contactEmail"
                                name="contactEmail"
                                value={contactInfo.contactEmail}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="gender">
                                Gender
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="gender"
                                name="gender"
                                value={contactInfo.gender}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="tags">
                                Tags
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="tags"
                                name="tags"
                                value={contactInfo.tags}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="d-flex my-2 justify-content-center mt-3">
                              <button
                                type="submit"
                                className="btn bg-green-filter text-center text-white  "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="p-4 bg-white contactsList_chat"
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "0px",
                  backgroundImage: `url(${Chat_BG})`,
                  backgroundRepeat: "repeat",
                  backgroundPosition: "center",

                  backgroundSize: "30%",
                }}
              >
                <>
                  {/* <div className="bg-dark p-5 m-5 text-white"> hiii{chats.length}</div> */}
                  {selectedContactId ? (
                    chats.length === 0 ? (
                      <div className="text-3-2 d-flex justify-content-center align-items-center ">
                        No Chat Found !
                      </div>
                    ) : (
                      chats.map((chat) => (
                        <div
                          key={chat.message_id}
                          style={{
                            textAlign: chat.sender === "1" ? "right" : "left",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            style={{ width: "20rem" }}
                            className={` message-container  ${
                              chat.sender === "1"
                                ? `sent${
                                    chat.message_type === "button"
                                      ? "1 p-0 pt-3"
                                      : ""
                                  } `
                                : "received"
                            }`}
                          >
                            <p
                              className={` message-text  ${
                                chat.sender === "1"
                                  ? "text-start "
                                  : "text-start"
                              }`}
                            >
                              <div className="d-flex"></div>
                              {chat.reply_to ? (
                                <div
                                  className="d-flex text-dark rounded mb-2"
                                  style={{
                                    border: "1px solid #ccc",
                                    backgroundColor: "#e6ffef",
                                  }}
                                >
                                  <div className="mx-3 text-break">
                                    {chat.reply_to}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "catalogue" ? (
                                <div
                                  className="d-flex bg-white text-dark rounded mb-1"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  <img
                                    style={{
                                      width: "7rem",
                                    }}
                                    src={food}
                                    alt=""
                                  />
                                  <div className="d-flex align-items-center p-1 text-mute">
                                    Your Catalogue on WhatsApp
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "catelogue_order" ? (
                                <div
                                  className="d-flex bg-white text-dark rounded mb-1"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  <img
                                    style={{
                                      width: "7rem",
                                    }}
                                    src={Cart}
                                    alt=""
                                  />
                                  <div className="d-flex align-items-center justify-content-end p-1 text-mute">
                                    {chat.message.slice(0, 8)}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                className={` ${
                                  chat.message_type === "button"
                                    ? "text-center"
                                    : ""
                                }`}
                              >
                                {chat.message_type === "catelogue_order" ? (
                                  <p className="fw-medium">
                                    {chat.message.slice(10)}
                                  </p>
                                ) : (
                                  <p className="fw-medium">{chat.message}</p>
                                )}
                              </div>
                            </p>
                            <p
                              className={` p-0 m-0  message-timestamp ${
                                chat.sender === "1" ? "text-end" : "text-end"
                              }`}
                            >
                              {chat.message_type === "catelogue_order" ? (
                                <hr />
                              ) : (
                                ""
                              )}
                              {chat.message_type === "catelogue_order" ? (
                                <div
                                  className="bg-white d-flex justify-content-center my-2 py-2 cursor-pointer rounded-2 "
                                  style={{
                                    color: " #01C37D",
                                    border: "2px solidrgb(201, 251, 162)",
                                  }}
                                  // onClick={() => navigate("/Orders/Catalogue")}
                                >
                                  View Sent Cart
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "catalogue" ? <hr /> : ""}
                              {chat.message_type === "catalogue" ? (
                                <div
                                  className="bg-white d-flex justify-content-center my-2 py-2 cursor-pointer rounded-2 "
                                  style={{
                                    color: " #01C37D",
                                    border: "2px solidrgb(201, 251, 162)",
                                  }}
                                  onClick={() => navigate("/Orders/Catalogue")}
                                >
                                  View Catalogue
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "button" ? (
                                ""
                              ) : (
                                <p class="card-text">
                                  <small class="text-body-secondary">
                                    {chat.datetime}
                                  </small>
                                </p>
                              )}
                            </p>
                          </div>
                          <div ref={messagesEndRef}></div>
                        </div>
                      ))
                    )
                  ) : (
                    <p>
                      <ContectBook userID={userID} fetchData={fetchData} />

                      {/* <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="w-50 bg-white "
                          style={{ border: "1px solid #ccc" }}
                        >
                          <div className="text-center bg-green-filter text-white  py-2 font-bold">
                            Create New Contact
                          </div>
                          <Form className="p-4">
                            <FormGroup className="d-flex py-1">
                              <FormLabel style={{ width: "5rem" }}>
                                Name <span className="text-danger">*</span>
                              </FormLabel>
                              <FormControl
                                type="text"
                                className={`${errorName ? "is-invalid" : ""}`}
                                placeholder="Enter contact name. "
                                value={contactName}
                                onChange={(e) => {
                                  setContactName(e.target.value);
                                  setErrorName("");
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="d-flex py-1">
                              <FormLabel style={{ width: "5rem" }}>
                                Mobile <span className="text-danger">*</span>
                              </FormLabel>
                              <FormControl
                                type="tel"
                                value={contactNumber}
                                className={`${errorMobile ? "is-invalid" : ""}`}
                                placeholder="Enter mobile number. "
                                onChange={(e) => {
                                  const input = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  ); // Remove any non-digit characters
                                  if (input.length <= 10) {
                                    // Check if the input has 10 or fewer digits
                                    setContactNumber(input); // Update the state with the sanitized input
                                    setErrorMobile("");
                                  }
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="d-flex py-1">
                              <FormLabel style={{ width: "5rem" }}>
                                E-mail
                              </FormLabel>
                              <FormControl
                                type="email"
                                value={contactEmail}
                                placeholder="Enter e-mail id. "
                                onChange={(e) =>
                                  setContactEmail(e.target.value)
                                }
                              />
                            </FormGroup>

                            <FormGroup className="d-flex py-1">
                              <FormLabel style={{ width: "5rem" }}>
                                Gender
                              </FormLabel>
                              <Form.Select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                aria-label="Select Gender"
                              >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </Form.Select>
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center py-1">
                              <FormLabel style={{ width: "5rem" }}>
                                Tags
                              </FormLabel>
                              <div className="w-100">
                                {tags.map((tag, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center mb-1"
                                  >
                                    <FormControl
                                      type="text"
                                      value={tag}
                                      placeholder="Enter tag"
                                      onChange={(e) =>
                                        handleTagChange(index, e.target.value)
                                      }
                                    />
                                    <Button
                                      className="m-1 ms-2 bg-white border-white"
                                      onClick={() => removeTag(index)}
                                    >
                                      {" "}
                                      <img src={cut} alt="" />{" "}
                                    </Button>
                                  </div>
                                ))}
                                <div className="d-flex justify-content-end">
                                  <div
                                    onClick={addTag}
                                    className="bg-white text-3-2 cursor-pointer text-decoration-underline   text-center d-flex border-none justify-content-center"
                                  >
                                    Add Tag
                                  </div>
                                </div>
                              </div>
                            </FormGroup>

                            <div className="d-flex justify-content-center pt-3 ">
                              <Button
                                onClick={addContectApi}
                                className="bg-green-filter  text-white px-4"
                              >
                              
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div> */}
                    </p>
                  )}
                </>
              </div>
            </>
          )}

          {selectedContactId && (
            <>
              <div
                className="px-4 d-flex"
                style={{ borderTop: "1px solid #ccc", padding: "10px" }}
              >
                <Form
                  style={{ width: "100%", marginRight: "2px" }}
                  onSubmit={handleSubmit}
                >
                  <FormGroup>
                    <FormControl
                      type="text"
                      className="p-2 my-2 w-100"
                      // value={messageText}
                      // onChange={(e) => setMessageText(e.target.value)}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                    />
                  </FormGroup>
                </Form>

                <button
                  type="submit"
                  className="bg-white border-0"
                  // onClick={handleSendMessage}
                  onClick={handleSubmit}
                >
                  <img src={sendImage} height={40} alt="" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Chats;
