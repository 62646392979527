import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import trash from "../../images/trash.svg";
import NoIndex from "../NoIndex";
import Chat_BG from "../../images/chat_BG.png";
import { Form, FormControl, FormGroup } from "react-bootstrap";
const CreateTemplate = ({ userID }) => {
  const buttonTypeSelectRef = useRef(null);
  const [includeHeader, setIncludeHeader] = useState(false);
  const [includeHeader_withoutVariable, setIncludeHeader_withoutVariable] =
    useState(false);
  const [includeFooter, setIncludeFooter] = useState(false);
  const [includeButtons, setIncludeButtons] = useState(false);
  const [template1, setTemplate1] = useState({ body: "" });
  const [variables, setVariables] = useState([]);
  const [template1header, setTemplate1header] = useState({ body: "" });
  const [variablesheader, setVariablesheader] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const [base64String, setBase64String] = useState("");
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({
    name: "",
    type: "",
    language: "",
    category: "",
    purpose: "",
    header: "",
    body: "",
    footer: "",
    headerType: "",
    buttonType: "",
    button: "",

    showButton4_quick1: "",
    showButton4_quick2: "",
    showButton4_quick3: "",

    showButton4Value_quick1: "",
    showButton4Value_quick2: "",
    showButton4Value_quick3: "",

    showButton4option_quick1: "",
    showButton4option_quick2: "",
    showButton4option_quick3: "",
    showButton4_quick3_url: "",
    selectButton: "",
  });
  const [response, setResponse] = useState(null);
  const [components, setComponents] = useState([]);
  const [isAddVariableClicked, setIsAddVariableClicked] = useState(false);

  const clearForm = () => {
    // Reset all individual states
    setIncludeHeader(false);
    setIncludeHeader_withoutVariable(false);
    setIncludeFooter(false);
    setIncludeButtons(false);
    setTemplate1({ body: "" });
    setVariables([]);
    setTemplate1header({ body: "" });
    setVariablesheader([]);
    setImageFile([]);
    setImageLink("");
    setBase64String("");
    setImageName("");
    setLoading(false);

    // Reset complex template state
    setTemplate({
      name: "",
      type: "",
      language: "",
      category: "",
      purpose: "",
      header: "",
      body: "",
      footer: "",
      headerType: "",
      buttonType: "",
      button: "",
      showButton4_quick1: "",
      showButton4_quick2: "",
      showButton4_quick3: "",
      showButton4Value_quick1: "",
      showButton4Value_quick2: "",
      showButton4Value_quick3: "",
      showButton4option_quick1: "",
      showButton4option_quick2: "",
      showButton4option_quick3: "",
      showButton4_quick3_url: "",
      selectButton: "",
    });

    // Reset any response or components
    setResponse(null);
    setComponents([]);
    setIsAddVariableClicked(false);

    // Reset the ref, if applicable
    if (buttonTypeSelectRef.current) {
      buttonTypeSelectRef.current.value = ""; // Clear the select reference
    }

    // Add any additional cleanup as required
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const originalName = file.name;
      const nameWithoutExt =
        originalName.substring(0, originalName.lastIndexOf(".")) ||
        originalName;
      console.log("Original Filename:", originalName);
      console.log("Filename without Extension:", nameWithoutExt);

      setImageFile([file]);
      setImageName(nameWithoutExt);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64String(reader.result);
        convertImage(nameWithoutExt, reader.result); // Pass the name and base64 string to the function
      };
      reader.readAsDataURL(file);
    }
  };
  const convertImage = async (imageName, base64String) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        userid: userID,
        item_name: imageName,
        type: "IMG",
        image_data: base64String,
      };
      const response = await axios.post(
        "https://bumppy.in/anlook/api/v1/uploadItemImage.php",
        requestBody,
        config
      );
      console.log(response.data.image_url);
      if (response.data.status === "0") {
        setImageLink(response.data.image_url);
        console.log(imageLink);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const bodyText = variables.map((variable) => variable.value);
    const formattedBodyText = [bodyText];
    const newComponents = [
      {
        type: "BODY",
        text: template1.body,
        example: {
          body_text: formattedBodyText,
        },
      },
    ];
    if (includeHeader && template.headerType === "Text") {
      const headerText = variablesheader.map((variable) => variable.value);
      newComponents.push({
        type: "HEADER",
        format: "TEXT",
        text: template1header.body,
        // example: {
        //   header_text: headerText,
        // },
      });
    }

    if (includeHeader_withoutVariable) {
      newComponents.push({
        type: "HEADER",
        format: "TEXT",
        text: template1header.body,
      });
    }

    if (includeFooter) {
      newComponents.push({
        type: "FOOTER",
        text: template.footer,
      });
    }

    if (includeButtons && template.purpose !== "1") {
      const buttons = [];
      if (template.showButton4_quick1) {
        buttons.push({
          type: "QUICK_REPLY",
          text: template.showButton4_quick1,
        });
      }
      if (template.showButton4_quick2) {
        buttons.push({
          type: "QUICK_REPLY",
          text: template.showButton4_quick2,
        });
      }

      if (template.showButton4_quick3 && template.showButton4_quick3_url) {
        buttons.push({
          type: "URL",
          text: template.showButton4_quick3,
          url: template.showButton4_quick3_url,
        });
      }
      newComponents.push({
        type: "BUTTONS",
        buttons: buttons,
      });
    }

    if (template.purpose === "1") {
      const buttons = [];
      setIncludeButtons(true);

      setTemplate({
        ...template,
        // showButton4_quick1: "Intrested",
        // showButton4_quick2:"Not Intrested",
        showButton4option_quick1: "4",
        showButton4option_quick2: "5",
        // showButton4Value_quick1:"Intrested",
        // showButton4Value_quick2:"Not Intrested",
      });
      buttons.push({
        type: "QUICK_REPLY",
        text: template.showButton4_quick1,
      });
      buttons.push({
        type: "QUICK_REPLY",
        text: template.showButton4_quick2,
      });

      if (template.showButton4_quick3 && template.showButton4_quick3_url) {
        buttons.push({
          type: "URL",
          text: template.showButton4_quick3,
          url: template.showButton4_quick3_url,
        });
      }
      newComponents.push({
        type: "BUTTONS",
        buttons: buttons,
      });
    }

    setComponents(newComponents);
  }, [
    includeHeader,
    includeFooter,
    includeButtons,
    template1.body,
    variables,
    template1header.body,
    variablesheader,
    template.footer,
    template.showButton4_quick1,
    template.showButton4_quick2,
    template.showButton4_quick3,
    template.showButton4_quick3_url,
    template.headerType,
    template.purpose,
  ]);

  const validateTemplate = () => {
    if (!template.name) {
      return "Template Name is required.";
    }
    if (!template.language) {
      return "Template Language is required.";
    }
    if (!template.category) {
      return "Template Category is required.";
    }
    if (!template.purpose) {
      return "Template Purpose is required.";
    }
    const bodyText = template1.body.trim();
    const words = bodyText.split(/\s+/);
    const variableWords = words.filter((word) => word.match(/^\{\{.*\}\}$/));
    if (variableWords.length > 0) {
      const nonVariableWords = words.filter(
        (word) => !word.match(/^\{\{.*\}\}$/)
      ).length;

      if (nonVariableWords < 3) {
        return "Body must contain at least three words when variables are added.";
      }
    } else {
      if (bodyText === "") {
        return "Body cannot be empty.";
      }
    }
    if (template.showButton4option_quick1 && !template.showButton4_quick1) {
      return "Button-1 name is required.";
    }
    if (template.showButton4option_quick2 && !template.showButton4_quick2) {
      return "Button-2 name is required.";
    }
    if (template.showButton4option_quick3 && !template.showButton4_quick3) {
      return "Button-3 name is required.";
    }
    if (
      template.showButton4option_quick3 &&
      template.showButton4_quick3 &&
      !template.showButton4_quick3_url
    ) {
      return "Enter Website URL";
    }
    return null;
  };

  const chooseAPI = async () => {
    if (template.headerType === "Image") {
      createTemplateApi_Image();
    } else {
      createTemplateApi();
    }
  };

  const createTemplateApi = async () => {
    if (includeHeader && !template1header.body) {
      toast.error("Please fill in the Header field.");
      return;
    }
    if (includeFooter && !template.footer) {
      toast.error("Please fill in the Footer field.");
      return;
    }
    if (
      includeButtons &&
      !template.showButton4option_quick1 &&
      !template.showButton4option_quick2 &&
      !template.showButton4option_quick3
    ) {
      toast.error("Please fill in the Button field.");
      return;
    }
    const validationError = validateTemplate();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    try {
      setLoading(true);

      const data = {
        userid: userID,
        secret: "string",
        name: template.name,
        language: template.language,
        category: template.category,
        purpose: template.purpose,
        components: components,
      };

      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/createTemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setResponse(responseData);
      if (responseData.responseCode === "00") {
        toast.success(responseData.responseMessage);
        clearForm();
      } else {
        toast.error(responseData.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to create template. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const createTemplateApi_Image = async () => {
    if (!imageLink) {
      toast.error("Please upload image in the header.");
      return;
    }

    if (includeFooter && !template.footer) {
      toast.error("Please fill in the Footer field.");
      return;
    }
    if (
      includeButtons &&
      !template.showButton4option_quick1 &&
      !template.showButton4option_quick2 &&
      !template.showButton4option_quick3
    ) {
      toast.error("Please fill in the Button field.");
      return;
    }
    const validationError = validateTemplate();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    try {
      setLoading(true);

      const data = {
        userid: userID,
        secret: "fjrdtunfgngf",
        name: template.name,
        language: template.language,
        category: template.category,
        //  image: imageLink,
        image:
          "https://bumppy.in/anlook/api/v1/uploads/catalog_items/_A1020_Screenshot2.png",
        components: components,
      };
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/createImageTemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setResponse(responseData);
      if (responseData.responseCode === "00") {
        toast.success(responseData.responseMessage);
      } else {
        toast.error(responseData.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to create template. Please try again later.");
    } finally {
      setLoading(false);
    }
  }; 

  const handleRemoveButton4_quick1 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick1: "",
      showButton4Value_quick1: "",
      showButton4option_quick1: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton4_quick2 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick2: "",
      showButton4Value_quick2: "",
      showButton4option_quick2: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton4_quick3 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick3: "",
      showButton4Value_quick3: "",
      showButton4option_quick3: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleCreateTemplate = () => {
    createTemplateApi();
  };

  const handleCategoryChange = (event) => {
    const templateCategory = event.target.value;
    setTemplate((prevState) => ({
      ...prevState,
      category: templateCategory,
    }));
  };

  const handleLanguageChange = (event) => {
    const templateLanguage = event.target.value;
    setTemplate((prevState) => ({
      ...prevState,
      language: templateLanguage,
    }));
  };

  const handlePurposeChange = (event) => {
    const templatePurpose = event.target.value;
    setTemplate((prevState) => ({
      ...prevState,
      purpose: templatePurpose,
    }));
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setTemplate1({ ...template, [name]: value });

    // Add condition to handle headerType changes
    if (name === "type" && value !== "Text") {
      // If headerType is not "Text", clear the header value
      setTemplate1((prevTemplate) => ({ ...prevTemplate, header: "" }));
    }
    const updatedVariables = variables.filter((variable) => {
      return value.includes(variable.label); // Check if the variable label exists in the new body text
    });
    setVariables(updatedVariables);
  };
  const handleInputChange1header = (e) => {
    const { name, value } = e.target;
    // setTemplate1header({ ...template, [name]: value });
    setTemplate1header({ ...template1header, [e.target.name]: e.target.value });
    // Add condition to handle headerType changes
    if (name === "type" && value !== "Text") {
      // If headerType is not "Text", clear the header value
      setTemplate1header((prevTemplate) => ({ ...prevTemplate, header: "" }));
    }
    const updatedVariables = variablesheader.filter((variable) => {
      return value.includes(variable.label); // Check if the variable label exists in the new body text
    });
    setVariablesheader(updatedVariables);
  };
  const handleHeaderTypeChange = (e) => {
    const { value } = e.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      headerType: value,
      // Clear header value if headerType is not "Text"
      header: value !== "Text" ? "" : prevTemplate.header,
    }));
  };
  const handleButtonTypeChange = (e) => {
    const { value } = e.target;
    if (value === "4") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        showButton4option_quick1: value,
      }));
    }
    if (value === "5") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        showButton4option_quick2: value,
      }));
    }
    if (value === "6") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        showButton4option_quick3: value,
      }));
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Transform the input to lowercase
    setTemplate((prevState) => ({
      ...prevState,
      [name]: name === "footer" ? value : value.toLowerCase(),
    }));
  };
  const addVariableheader = () => {
    if (!isAddVariableClicked) {
      const nextVarNum = variablesheader.length + 1;

      // Ensure consistent formatting by adding curly braces around the variable
      const variableToAdd = ` {{${nextVarNum}}} `;
      setTemplate1header((prevTemplate) => ({
        ...prevTemplate,
        body: (prevTemplate.body || "") + variableToAdd,
      }));
      // Add the variable to the variables array
      setVariablesheader((prevVariables) => [
        ...prevVariables,
        { label: variableToAdd, value: "" },
      ]);
      setIsAddVariableClicked(true);
    }
  };
  const addVariable = () => {
    // Calculate the next variable number
    const nextVarNum = variables.length + 1;

    // Ensure consistent formatting by adding curly braces around the variable
    const variableToAdd = ` {{${nextVarNum}}} `;

    // Insert the variable into the body text
    setTemplate1((prevTemplate) => ({
      ...prevTemplate,
      body: (prevTemplate.body || "") + variableToAdd,
    }));

    // Add the variable to the variables array
    setVariables((prevVariables) => [
      ...prevVariables,
      { label: variableToAdd, value: "" },
    ]);
  };

  const handleVariableValueChangeheader = (index, value) => {
    setVariablesheader((prevVariables) => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index].value = value;
      return updatedVariables;
    });
  };

  const replaceVariablesheader = (body) => {
    return body.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = variablesheader[variableIndex];
      return variable ? variable.value : ""; // Return variable value if present, otherwise return nothing
    });
  };

  const handleVariableValueChange = (index, value) => {
    setVariables((prevVariables) => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index].value = value;
      return updatedVariables;
    });
  };

  const replaceVariables = (body) => {
    return body.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = variables[variableIndex];
      return variable ? variable.value : ""; // Return variable value if present, otherwise return nothing
    });
  };
  const handleHeaderToggle = () => {
    setIncludeHeader((prevState) => !prevState);
  };
  const handleFooterToggle = () => {
    setIncludeFooter((prevState) => !prevState);
  };
  const handleButtonToggle = () => {
    setIncludeButtons((prevState) => !prevState);
  };

  const componentArrayJSON = JSON.stringify(components, null, 2);
  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>

      <div className="p-4  " style={{ fontFamily: "Nunito,sans-serif" }}>
        <div className=" bg-white px-4">
          <p className="CreateCampaign px-4">Create New Template</p>
          <div className="w-100 d-flex justify-content-between">
            <div className="form col-7 px-4 py-3">
              {loading ? (
                <div className=" m-5 p-5 d-flex justify-content-center">
                  <div className="loader "></div>
                </div>
              ) : (
                <Form>
                  {/* ######################     Template     ##################*/}
                  <div className=" p-3 border-template">
                    <FormGroup>
                      <Form.Label className="text2 ">Template Name</Form.Label>
                      <FormControl
                        className=""
                        type="text"
                        name="name"
                        placeholder="Enter Template Name"
                        value={template.name}
                        onChange={handleInputChange}
                      />
                    </FormGroup>

                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Language
                      </Form.Label>

                      <Form.Select
                        className=""
                        name="templateLanguage"
                        aria-label="Select Language"
                        onChange={handleLanguageChange}
                        value={template.language}
                      >
                        <option value="">Select Language</option>
                        <option value="en_US">English</option>
                        <option value="hi">Hindi</option>
                      </Form.Select>
                    </FormGroup>

                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Category
                      </Form.Label>

                      <Form.Select
                        className=""
                        name="templateCategory"
                        aria-label=""
                        onChange={handleCategoryChange}
                        value={template.category}
                      >
                        <option value="">Select Category</option>
                        <option value="MARKETING">Marketing</option>
                        <option value="AUTHENTICATION">Authentication</option>
                      </Form.Select>
                    </FormGroup>

                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Purpose
                      </Form.Label>

                      <Form.Select
                        className=""
                        name="templatepurpose"
                        aria-label=""
                        onChange={handlePurposeChange}
                        value={template.purpose}
                      >
                        <option value="">Select Purpose</option>
                        <option value="1">Lead Generation</option>
                        <option value="2">Marketing & Awareness</option>
                      </Form.Select>
                    </FormGroup>
                  </div>
                  {/* ######################      Header     ##################*/}
                  <div className=" p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeHeader}
                          onChange={handleHeaderToggle}
                          id="headerSwitch"
                        />
                      </div>
                      <label
                        className="form-check-label text-3-2 mb-1 mx-2"
                        htmlFor="headerSwitch"
                      >
                        Header
                      </label>
                      <label
                        className="form-check-label  mb-1 mx-2 d-flex justify-content-center"
                        htmlFor="headerSwitch"
                      >
                        {/* <span className="nav-text font-bold">
                          <span className="text-danger">*</span>Only 1 variable
                          allowed.
                        </span> */}
                      </label>
                    </FormGroup>
                    {includeHeader && (
                      <>
                        <FormGroup>
                          <Form.Label className="text2 mt-3">
                            Header Type
                          </Form.Label>
                          <FormControl
                            className=""
                            as="select"
                            name="headerType"
                            value={template.headerType}
                            onChange={handleHeaderTypeChange}
                          >
                            <option value="">Select Header Type</option>
                            <option value="Text">Text</option>
                            <option value="Image">Image</option>
                          </FormControl>
                        </FormGroup>
                        {template.headerType === "Text" && (
                          <>
                            <FormGroup>
                              <Form.Label className="text2 mt-3">
                                Header
                              </Form.Label>
                              <FormControl
                                className=""
                                as="textarea"
                                name="body"
                                value={template1header.body}
                                onChange={handleInputChange1header}
                              />
                            </FormGroup>
                            {/* <div className=" d-flex justify-content-end">
                              <div
                                className="-filter px-2 border-template m-2 text-white"
                                onClick={addVariableheader}
                                disabled={isAddVariableClicked}
                                style={{
                                  cursor: isAddVariableClicked
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                +
                              </div>
                            </div> */}

                            {variablesheader.map((variableheader, index) => (
                              <FormGroup key={index} className="d-flex py-1">
                                <Form.Label className="pe-2">
                                  {variableheader.label}
                                </Form.Label>
                                <FormControl
                                  type="text"
                                  value={variableheader.value}
                                  onChange={(e) =>
                                    handleVariableValueChangeheader(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            ))}
                          </>
                        )}

                        {template.headerType === "Image" && (
                          <div>
                            <div className="row d-flex justify-content-between p-2 pt-4">
                              <div
                                className="col-12"
                                // style={{ borderRight: "1px dashed #000" }}
                              >
                                <div className="">
                                  <label htmlFor={`image-upload`}>
                                    Upload image in header :{" "}
                                  </label>
                                </div>
                                <input
                                  type="file"
                                  id={`image-upload`}
                                  className="pt-2"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                />
                              </div>
                            </div>
                            {imageFile.length > 0 && (
                              <div className="d-flex justify-content-center p-2 pt-4">
                                <img
                                  src={URL.createObjectURL(imageFile[0])}
                                  alt="Uploaded"
                                  style={{ maxWidth: "50%" }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* ###################### BODY #######################*/}
                  <div className=" p-3 border-template my-3">
                    <FormGroup>
                      <Form.Label className="text2 ">Body</Form.Label>
                      <FormControl
                        className=" mt-3"
                        as="textarea"
                        name="body"
                        value={template1.body}
                        onChange={handleInputChange1}
                      />
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                      <div
                        className="blue-div px-2  my-2 text-white cursor-pointer blue-button"
                        onClick={addVariable}
                      >
                        Add variable
                      </div>
                    </div>
                    {variables.map((variable, index) => (
                      <FormGroup key={index} className="d-flex py-1">
                        <Form.Label className="pe-2">
                          {variable.label}
                        </Form.Label>
                        <FormControl
                          type="text"
                          value={variable.value}
                          onChange={(e) =>
                            handleVariableValueChange(index, e.target.value)
                          }
                        />
                      </FormGroup>
                    ))}
                  </div>
                  {/* ###################### Footer #######################*/}
                  <div className=" p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeFooter}
                          onChange={handleFooterToggle}
                          id="footerSwitch"
                        />
                      </div>
                      <label
                        className="form-check-label text-3-2 mb-1 mx-2"
                        htmlFor="footerSwitch"
                      >
                        Footer (Optional)
                      </label>
                    </FormGroup>
                    {includeFooter && (
                      <>
                        <FormGroup>
                          <FormControl
                            className=" mt-3"
                            type="text"
                            name="footer"
                            value={template.footer}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                  {/* ###################### Button #######################*/}
                  <div className=" p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeButtons}
                          onChange={handleButtonToggle}
                          id="buttonSwitch"
                        />
                      </div>
                      <label className="form-check-label text-3-2 mb-1 mx-2 d-flex justify-content-center">
                        <span>Buttons </span>
                      </label>
                      <label className="form-check-label  mb-1 mx-2 d-flex justify-content-center">
                        <span className="nav-text font-bold">
                          <span className="text-danger">*</span>Maximum 3
                          buttons allowed.
                        </span>
                      </label>
                    </FormGroup>
                    {includeButtons && (
                      <>
                        <FormGroup>
                          <FormControl
                            className=" mt-3"
                            as="select"
                            name="selectButton"
                            onChange={handleButtonTypeChange}
                            ref={buttonTypeSelectRef}
                          >
                            <option value="">Select Button Type</option>
                            <option value="4">Quick Reply Button 1</option>
                            <option value="5">Quick Reply Button 2</option>
                            <option value="6">Visit Website</option>
                          </FormControl>
                        </FormGroup>
                        {template.showButton4option_quick1 === "4" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              <span>Quick Reply Button 2</span>
                              {template.purpose === "1" && (
                                <>
                                  <span className="footer-text ps-2">
                                    (Example: Interested )
                                  </span>
                                </>
                              )}
                            </Form.Label>
                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className=" "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick1}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick1: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-2">
                                <div
                                  className="-filter p-2 cursor-pointer text-center border-template  text-white "
                                  onClick={(e) => {
                                    handleRemoveButton4_quick1();
                                  }}
                                >
                                  <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-4"></FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton4option_quick2 === "5" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              <span>Quick Reply Button 2</span>
                              {template.purpose === "1" && (
                                <>
                                  <span className="footer-text ps-2">
                                    (Example: Not Interested )
                                  </span>
                                </>
                              )}
                            </Form.Label>
                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className=" "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick2}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick2: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="col-2">
                                <div
                                  className="-filter p-2 cursor-pointer text-center border-template  text-white "
                                  onClick={(e) => {
                                    handleRemoveButton4_quick2();
                                  }}
                                >
                                  <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-4"></FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton4option_quick3 === "6" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              Visit website
                            </Form.Label>
                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className=" "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick3}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick3: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-4">
                                <FormControl
                                  className=" "
                                  type="text"
                                  placeholder="URL"
                                  name="buttonUrl"
                                  value={template.showButton4_quick3_url}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick3_url: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-2">
                                <div
                                  className="-filter p-2 cursor-pointer text-center border-template  text-white "
                                  onClick={(e) => {
                                    handleRemoveButton4_quick3();
                                  }}
                                >
                                  <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className=" d-flex justify-content-start">
                    <div
                      className="blue-div px-5 py-2 my-3 text-3-2 cursor-pointer  blue-button"
                      onClick={chooseAPI}
                    >
                      Submit
                    </div>
                  </div>
                </Form>
              )}
            </div>

            <div className="col-5  p-4 ">
              <div className="">
                <div className="pt-4  align-items-center d-flex justify-content-center">
                  <div className="">
                    <div className="pb-2 text2 text-center">
                      Sample Message Preview
                    </div>

                    <div
                      className="p-4  shadow"
                      style={{
                        width: "18rem",
                        minHeight: "35rem",
                        borderRadius: "30px",
                        border: "10px solid #000",
                        position: "relative",
                        backgroundImage: `url(${Chat_BG})`,
                        backgroundRepeat: "repeat",
                        backgroundPosition: "center",
                        boxSshadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
                        backgroundSize: "100%",
                      }}
                    >
                      <div
                        className="  w-100"
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        <div className="d-flex justify-content-center ">
                          {" "}
                          <div
                            className="mt-2"
                            style={{
                              backgroundColor: "#000",
                              borderRadius: "30px",
                              width: "6rem",
                            }}
                          >
                            -
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        className="row  p-2 m-3"
                        style={{ position: "absolute", bottom: 0, left: 0 }}
                      >
                        <div className="chat-border pt-2">
                          {imageFile.length > 0 ? (
                            <img
                              src={URL.createObjectURL(imageFile[0])}
                              alt="Uploaded"
                              style={{ maxWidth: "100%" }}
                            />
                          ) : (
                            <div className="header-text text-white">
                              {replaceVariablesheader(template1header.body) ||
                                "Header"}
                            </div>
                          )}
                          <div className="body-text text-white py-1">
                            {/* {template1.body||" Enter the text for your message in the language that you've selected."} */}
                            {replaceVariables(template1.body) ||
                              "Enter the text for your message in the language that you've selected."}
                          </div>

                          <div className="footer-text text-white">
                            {" "}
                            {template.footer || "Footer"}
                          </div>
                          <div className="footer-text text-end pb-2 text-white">
                            {" "}
                            05:27 PM
                          </div>

                          <div className="d-flex"></div>
                        </div>
                        <div className="">
                          {template.showButton4_quick1 !== "" && (
                            <>
                              <div className=" showButton bg-white my-2 ">
                                {template.showButton4_quick1}
                              </div>
                            </>
                          )}
                          {template.showButton4_quick2 !== "" && (
                            <>
                              <div className=" showButton my-2 ">
                                {template.showButton4_quick2}
                              </div>
                            </>
                          )}

                          {template.showButton4_quick3 !== "" && (
                            <>
                              <div className=" showButton my-2 ">
                                {template.showButton4_quick3}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTemplate;
