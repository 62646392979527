import React, { createContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  
  const [newCustomAttributeDetails, setNewCustomAttributeDetails] = useState({
    name: "",
    key: "",
    format: "",
  });

  return (
    <MyContext.Provider
      value={{
        setNewCustomAttributeDetails,
        newCustomAttributeDetails,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
