import React, { useState, useContext, useEffect } from "react";

// import { MdOutlineDashboardCustomize } from "react-icons/md";
// import { IoCloseCircleOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";

import MyContext from "../../../../MyContext";

const NewCustomAttributeModal = ({}) => {
  const {
   
    setNewCustomAttributeDetails,
    newCustomAttributeDetails,
  } = useContext(MyContext);

  const [isFormatListDropdownOpen, setFormatListDropdownOpen] = useState(false);
  const [selectedFormatType, setSelectedFormatType] = useState("");
  const [name, setName] = useState("");
  const [key, setKey] = useState("");

  const FormatList = [
    "Short Text (Max 250 characters)",
    "Number",
    "Decimal Number",
    "True or False",
    "Date",
    "Long Text (Max 2048 characters)",
    "List (Single Selection)",
    "List (Multi Selection)",
    "Event",
  ];

useEffect(()=>{

    setNewCustomAttributeDetails({
        name: name,
        key: key,
        format: selectedFormatType,
      });

},[key, name, selectedFormatType])

const handleCreate = () => {
    if (!name || !selectedFormatType) {
      toast.error("Please fill in the required fields!"); 
      return; 
    }
  
    // Reset inputs
    setName("");
    setKey("");
    setSelectedFormatType("");
  
    console.log(newCustomAttributeDetails, "new custom attribute details>>>");
    toast.success("Attribute created successfully!");
    
  };

  return (
    <>
      {/* Header */}
      {/* <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "16px" }}
      >
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
          <h5
            className="align-items-center d-flex justify-content-center gap-2"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            <MdOutlineDashboardCustomize
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            />
            New Custom Attribute
          </h5>
        </div>
        <IoCloseCircleOutline
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ fontWeight: "bold", fontSize: "1.5rem" }}
        />
      </div> */}

      {/* content */}
      <div className="d-flex flex-column px-4 py-2">
        <label className="">Name</label>
        <input
          style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-2 d-flex"
          placeholder="Enter custom attribute label"
        />
      </div>
      <div className="d-flex flex-column px-4 py-2">
        <label className="">Key (Optional)</label>
        <input
          style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          className="p-2 d-flex"
          placeholder="Enter custom attribute key"
        />
      </div>
      <div className="d-flex flex-column px-4 py-2 mb-4">
        <label className="">Format</label>
        {/* Add Format Dropdown */}
        <div
          style={{
            border: "1px solid lightgrey",
            borderRadius: "5px",
            padding: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          className="dropdown-hovereffect"
          onClick={() => setFormatListDropdownOpen(!isFormatListDropdownOpen)}
        >
          <span>{selectedFormatType || "Format"}</span>
          <IoIosArrowDown style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
        </div>

        {/* dropdown */}
        {isFormatListDropdownOpen && (
          <div
            style={{
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: "999",
              borderRadius: "5px",
              position: "absolute",
              background: "white",
              width: "95%",
            }}
            className="mt-4"
          >
            {FormatList.map((FormatType, index) => (
              <h2
                className="m-0 p-3 dropdown-hovereffect cursor-pointer"
                key={index}
                style={{ fontSize: "1rem" }}
                onClick={() => {
                  setSelectedFormatType(FormatType);
                  setFormatListDropdownOpen(false);
                }}
              >
                {FormatType}
              </h2>
            ))}
          </div>
        )}
      </div>

      {/* footer */}
      <div
        className="gap-2 d-flex justify-content-end p-4 dropdown-hovereffect"
        style={{ width: "100%", borderTop: "1px solid lightgrey" }}
      >
        <button
          className="btn"
          style={{
            border: "1px solid lightgrey",
            borderRadius: "5px",
            background: "white",
          }}
          data-bs-dismiss="modal"
          aria-label="Close"
         
        >
          Cancel
        </button>
        <button
          className="btn"
          style={{
            background: "rgb(1, 195, 124)",
            border: "none",
            borderRadius: "5px",
            color: "white",
          }}
          onClick={()=>handleCreate()}
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Create
        </button>
      </div>
    </>
  );
};

export default NewCustomAttributeModal;
