import React from 'react';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";
import ReactDOM from 'react-dom/client';

import { MyContextProvider } from "./MyContext";
// import { Provider } from 'react-redux';
// import { store } from './app/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MyContextProvider>
    <App />
  </MyContextProvider>
);