import React, { useState, useContext } from "react";

import { X, MoreVertical, Link2, PenSquare } from "lucide-react";

import { Toast } from "bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { MdHistory } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { CiChat1 } from "react-icons/ci";
import { RiContactsBook3Line } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { ImTicket } from "react-icons/im";
import { IoMdAdd } from "react-icons/io";
import { PiHourglassSimpleMediumFill } from "react-icons/pi";
import { RiFileCopyLine } from "react-icons/ri";
import { FaBan } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoPencil } from "react-icons/io5";
import { LuSmile } from "react-icons/lu";
import { IoPersonSharp } from "react-icons/io5";
import { CgFlagAlt } from "react-icons/cg";
import { BsInfoCircle } from "react-icons/bs";
import { RiTimelineView } from "react-icons/ri";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaRegHourglass } from "react-icons/fa";
import { RiComputerLine } from "react-icons/ri";

import ContactsTab from "./subComponents/ContactsTab";
import logoImage from "../../../images/AnlookSymbol.svg";
import "./Support.css";

import MyContext from "../../../MyContext";

const Support = () => {


  const [activeTab, setActiveTab] = useState("chat");
  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Open");
  const [isAddTagIcon, setAddTagIcon] = useState(false);
  const [isTagModalOpen, setTagModalOpen] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState(["example"]);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  const [createTicketModalOpen, setCreateTicketModal] = useState(false);
  const [sessionInfoModalOpen, setSessionInfoModal] = useState(false);
  const [copyModalOpen, setCopyModal] = useState(false);

  const [banModalOpen, setBanModal] = useState(false);
  const [feedbackModalOpen, setFeedbackModal] = useState(false);

  const [gmailEntered, setGmailEntered] = useState("");

  const [activeSessionInfoTab, setActiveSessionInfoTab] = useState("info");
  const [banIpChecked, setBanIpChecked] = useState(false);

  const statusDropdown = [
    {
      key: (
        <React.Fragment>
          <div
            style={{
              padding: "0.5rem",
              background: "orange",
              height: "0.5rem",
              borderRadius: "5px",
            }}
          ></div>
        </React.Fragment>
      ),
      value: "Open",
    },
    {
      key: (
        <React.Fragment>
          <div
            style={{
              padding: "0.5rem",
              background: "grey",
              height: "0.5rem",
              borderRadius: "5px",
            }}
          ></div>
        </React.Fragment>
      ),
      value: "Closed",
    },
  ];

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const handleStatusChange = (obj) => {
    setSelectedStatus(obj.value);
    setStatusDropdownOpen(false);
  };

  const handleAddTag = () => {
    setTagModalOpen(!isTagModalOpen);
    setMoreMenuOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (newTag && !tags.includes(newTag)) {
        setTags((prevTags) => [...prevTags, newTag]);
        setNewTag(""); // Clear the input field
      }
      e.preventDefault(); // Prevent form submission or other default behaviors
    }
  };

  const handleMoreMenuOpen = () => {
    setMoreMenuOpen(!isMoreMenuOpen);
    setStatusDropdownOpen(false);
  };

  const handleCreateTicket = () => {
    setMoreMenuOpen(false);
    setTagModalOpen(false);
  };

  const handleSessionInfo = () => {
    setMoreMenuOpen(false);
  };

  const handleCopy = () => {
    setMoreMenuOpen(false);
  };

  const handleBan = () => {
    setMoreMenuOpen(false);
  };

  const handleFeedback = () => {
    setMoreMenuOpen(false);
  };

  const handleDelete = () => {
    toast.success("Successfully deleted chat");
    setMoreMenuOpen(false);
  };

  // session info modal

  const handleSessionInfoTabSwitch = (tab) => {
    setActiveSessionInfoTab(tab);
  };

  const handleCopyConversation = () => {
    const text = "Conversation started on Monday, January 13 2025, 16:23";
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleBanCheckboxChange = () => {
    setBanIpChecked(!banIpChecked);
  };

  return (
      <div>
            {/* History Detail Modal */}
          <div
            className="marginRight justify-content-center  d-flex flex-column bg-white "
            style={{
              margin: "1rem 10rem 0rem 30rem",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              maxWidth: '700px'
            }}
          >
            {/* Header */}
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ padding: "16px" }}
            >
              <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                <h5
                  className="align-items-center d-flex justify-content-center gap-2"
                  style={{ fontWeight: "bold" }}
                >
                  {" "}
                  <MdHistory style={{ fontWeight: "bold", fontSize: "1.5rem" }} />
                  History Details
                </h5>
              </div>
              <IoCloseCircleOutline
                style={{ fontWeight: "bold", fontSize: "1.5rem" }}
              />
            </div>

            {/* Tab Bar */}
            <div style={{ display: "flex", borderBottom: "2px solid #e0e0e0" }}>
              <div
                onClick={() => {
                  handleTabSwitch("chat");
                }}
                style={{
                  flex: "1",
                  padding: "16px",
                  textAlign: "center",
                  borderBottom: activeTab === "chat" ? "4px solid #10B981" : "none",
                  color: activeTab === "chat" ? "#10B981" : "#6b7280",
                  fontWeight: "bold",
                }}
              >
                <CiChat1 style={{ fontSize: "1.5rem" }} />
              </div>
              <div
                onClick={() => {
                  handleTabSwitch("contacts");
                }}
                style={{
                  flex: "1",
                  padding: "16px",
                  textAlign: "center",
                  borderBottom:
                    activeTab === "contacts" ? "4px solid #10B981" : "none",
                  color: activeTab === "contacts" ? "#10B981" : "#6b7280",
                  fontWeight: "bold",
                }}
              >
                <RiContactsBook3Line
                  style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                />
              </div>
            </div>

            {activeTab == "chat" ? (
              <>
                {/* Chat ID Section */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#f87171",
                        borderRadius: "5px",
                      }}
                    />
                    <span>V1736373658431987 - Anlook</span>
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <span
                      className="d-flex gap-2 "
                      style={{
                        border: "1px solid lightgrey",
                        padding: "0.5rem",
                        borderRadius: "5px",
                      }}
                      onClick={() => setStatusDropdownOpen(!isStatusDropdownOpen)}
                    >
                      {selectedStatus}
                      <IoIosArrowDown
                        style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                      />
                    </span>
                    {isStatusDropdownOpen && (
                      <div
                        style={{
                          width: "10%",
                          boxShadow: "0 0 5px lightgrey",
                          zIndex: 999,
                          position: "absolute",
                          background: "white",
                        }}
                        className="d-flex flex-column my-4"
                      >
                        {statusDropdown.map((obj, index) => (
                          <div
                            key={index}
                            value={obj.value}
                            className="statusDropdownItem p-2 d-flex gap-2 align-items-center"
                            style={{}}
                            onClick={() => handleStatusChange(obj)}
                          >
                            {obj.key}
                            {obj.value}
                          </div>
                        ))}
                      </div>
                    )}
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        padding: "0.5rem",
                        borderRadius: "5px",
                      }}
                    >
                      <IoPricetagsOutline
                        style={{ width: "20px", height: "20px" }}
                        onMouseEnter={() => setAddTagIcon(true)}
                        onClick={() => setTagModalOpen(false)}
                      />
                      {isAddTagIcon && (
                        <div
                          style={{
                            boxShadow: "0 0 5px lightgrey",
                            zIndex: 999,
                            position: "absolute",
                            background: "white",
                            fontSize: "1rem",
                          }}
                          onMouseLeave={() => setAddTagIcon(false)}
                          className="d-flex flex-column my-1 p-2"
                          onClick={() => handleAddTag()}
                        >
                          <IoMdAdd />
                        </div>
                      )}
                      {isTagModalOpen && (
                        <div
                          style={{
                            boxShadow: "0 0 5px lightgrey",
                            zIndex: 999,
                            position: "absolute",
                            background: "white",
                            fontSize: "1rem",
                          }}
                          onMouseLeave={() => setAddTagIcon(false)}
                          className="d-flex flex-column my-1 p-2"
                        >
                          <input
                            placeholder="Search or create"
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                              padding: "0.5rem",
                            }}
                            onChange={(e) => {
                              setNewTag(e.target.value);
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                          <hr />
                          {tags.map((tag) => (
                            <p>{tag}</p>
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      <MoreVertical
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => handleMoreMenuOpen()}
                      />
                      {isMoreMenuOpen && (
                        <div
                          style={{
                            boxShadow: "0 0 5px lightgrey",
                            zIndex: 999,
                            position: "absolute",
                            background: "white",
                            fontSize: "1rem",
                          }}
                          className="d-flex flex-column m-0"
                        >
                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2 m-0"
                            onClick={() => handleCreateTicket()}
                            data-bs-toggle="modal"
                            data-bs-target="#createTicketModal"
                          >
                            <ImTicket />
                            Create Ticket
                          </span>
                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#sessionInfoModal"
                            onClick={() => handleSessionInfo()}
                          >
                            <PiHourglassSimpleMediumFill />
                            Session Information
                          </span>
                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#copyModal"
                            onClick={() => handleCopy()}
                          >
                            <RiFileCopyLine />
                            Copy
                          </span>

                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#banModal"
                            onClick={() => handleBan()}
                          >
                            <FaBan />
                            Ban
                          </span>
                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#feedbackModal"
                            onClick={() => handleFeedback()}
                          >
                            <HiPencilAlt />
                            AI Feedback
                          </span>
                          <hr className="p-0 m-0" />
                          <span
                            className="gap-2 d-flex align-items-center statusDropdownItem p-2"
                            onClick={() => handleDelete()}
                          >
                            <RiDeleteBin7Line />
                            Delete
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Date Indicator */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "16px",
                  }}
                >
                  <span
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#f3f4f6",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Thursday, January 9th 2025
                  </span>
                </div>

                {/* Navigation Info */}
                <div style={{ padding: "16px", fontSize: "14px" }}>
                  <span style={{ color: "#6b7280" }}>Visitor navigated to </span>
                  <a href="#" style={{ color: "#3b82f6" }}>
                    Anlook | Whatsapp Campaign Management for businesses
                  </a>
                </div>

                {/* Chat Messages */}
                <div
                  style={{
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {/* System Message */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <div style={{ maxWidth: "80%" }}>
                      <div
                        className="d-flex justify-content-end"
                        style={{
                          fontSize: "12px",
                          color: "#6b7280",
                          marginBottom: "4px",
                        }}
                      >
                        Customer Support (System Message)
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f3f4f6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                        className="d-flex gap-2"
                      >
                        <div>👋 Hi! How can we help?</div>
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#6b7280",
                            width: "3rem",
                          }}
                          className="d-flex align-items-end"
                        >
                          3:30 AM
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#f87171",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      C
                    </div>
                  </div>

                  {/* User Message */}
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#f87171",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#6b7280",
                          marginBottom: "4px",
                        }}
                      >
                        V1736373658431987
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f3f4f6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                        className="d-flex gap-2"
                      >
                        <div>Tell me more</div>
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#6b7280",
                            width: "3rem",
                          }}
                          className="d-flex align-items-end"
                        >
                          3:31 AM
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* System Response */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <div style={{ maxWidth: "80%" }}>
                      <div
                        className="d-flex justify-content-end"
                        style={{
                          fontSize: "12px",
                          color: "#6b7280",
                          marginBottom: "4px",
                        }}
                      >
                        Customer Support (System Message)
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f3f4f6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                        className="d-flex gap-2"
                      >
                        <div>
                          Sorry to keep you waiting, unfortunately all of our agents
                          are currently busy or away, please leave a message and we
                          will get back to you as soon as possible. What's the best
                          email to reach you on?
                        </div>
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#6b7280",
                            width: "20%",
                          }}
                          className="d-flex align-items-end"
                        >
                          3:32 AM
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#f87171",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      C
                    </div>
                  </div>
                </div>

                {/* Create Ticket Button */}
                <div
                  style={{ padding: "16px" }}
                  className="d-flex justify-content-center"
                >
                  <div
                    style={{
                      backgroundColor: "#10B981",
                      color: "white",
                      padding: "12px 16px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#createTicketModal"
                    onClick={() => handleCreateTicket()}
                  >
                    <ImTicket />
                    Create Ticket
                  </div>
                </div>

                {/* Create Ticket modal */}
                <div
                  class="modal fade"
                  id="createTicketModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"

                >
                  <div
                    class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "60%" }}
                  >
                    <div class="modal-content">
                      {/* Header */}
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          padding: "16px",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ gap: "8px" }}
                        >
                          <p
                            className="align-items-center d-flex justify-content-center gap-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Create Ticket
                          </p>
                        </div>
                        <X
                          style={{ fontSize: "1.5rem" }}
                          aria-label="Close"
                          data-bs-dismiss="modal"
                        />
                      </div>

                      {/* Form Content */}
                      <div className="p-3" style={{ overflow: "scroll" , height: '60vh'}}>
                        {/* Contact */}
                        <div className="mb-3 gap-1">
                          <label className="">Contact</label>
                          <div
                            className="d-flex align-items-center justify-content-between p-2"
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              <span
                                className=" px-2 py-1 rounded me-2"
                                style={{
                                  fontSize: "12px",
                                  color: "white",
                                  fontWeight: "bold",
                                  background: "#F39431",
                                }}
                              >
                                V
                              </span>
                              <span>V173441031178171</span>
                            </div>
                            <div>
                              <X
                                size={16}
                                className="ms-auto cursor-pointer text-secondary"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Email Address */}
                        <div className="mb-3 d-flex flex-column gap-1">
                          <label className="">Email Address</label>
                          <div className="dropdown ">
                            <button
                              className=" dropdown-toggle text-start d-flex align-items-center justify-content-between p-2"
                              type="button"
                              // data-bs-toggle="dropdown"
                              style={{
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                                width: "100%",
                                background: "transparent",
                              }}
                            >
                              <span className="">New Email</span>
                            </button>
                          </div>
                          <input
                            placeholder="example@gmail.com"
                            value={gmailEntered}
                            onChange={(e) => setGmailEntered(e.target.value)}
                            className="d-flex align-items-center justify-content-between p-2"
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                            }}
                          />
                        </div>

                        {/* Subject */}
                        <div className="mb-3">
                          <label className="form-label">Subject</label>
                          <input
                            type="text"
                            className="form-control"
                            value="RE : Chat with V173441031178171"
                          />
                        </div>

                        {/* Message */}
                        <div className="mb-3">
                          <label className="form-label">Message</label>
                          <div className="border rounded">
                            <div className="border-bottom p-2 bg-light d-flex gap-2 justify-content-end">
                              <button
                                className="btn btn-warning px-2 py-1 "
                                style={{ color: "white" }}
                              >
                                B
                              </button>
                              <span className="d-flex align-items-center">
                                Bumppy
                              </span>
                              <button
                                className="btn px-2 py-1"
                                style={{ background: "#F39431" }}
                              >
                                <CgFlagAlt style={{ color: "white" }} />
                              </button>
                            </div>
                            <div className="p-2">
                              <textarea
                                className="form-control border-0"
                                rows="6"
                                style={{ resize: "none" }}
                              ></textarea>
                              <div className="d-flex gap-3 mt-4 text-secondary">
                                <IoPencil />
                                <LuSmile />
                                <IoPersonSharp />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Attachments */}
                        <div className="mb-3">
                          <label className="form-label">Attachments</label>
                          <div
                            className=" p-3 text-center text-secondary"
                            style={{
                              border: "1px dashed lightgrey",
                              borderRadius: "5px",
                            }}
                          >
                            Drop your files here or{" "}
                            <span className="text-success">browse</span>
                          </div>
                        </div>

                        {/* Tags */}
                        <div className="mb-3">
                          <label className="form-label">Tags</label>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="btn btn-light border p-2"
                              style={{ borderRadius: "5px" }}
                            >
                              <IoMdAdd />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Footer */}
                      <div className="d-flex justify-content-end gap-2 p-3 border-top">
                        <button
                          className="btn btn-light"
                          aria-label="Close"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button className="btn btn-success">Create</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Session Info modal */}
                <div
                  class="modal fade"
                  id="sessionInfoModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"

                >
                  <div
                    class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "60%" }}
                  >
                    <div class="modal-content">
                        {/* Header */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ padding: "16px", borderbottom: "1px solid lightgrey" }}
                >
                  <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                    <p
                      className="align-items-center d-flex justify-content-center gap-2"
                      style={{ fontWeight: "bold" }}
                    >
                      Session Information
                    </p>
                  </div>

                  <X
                    style={{ fontSize: "1.5rem" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => setSessionInfoModal(false)}
                  />
                </div>

                {/* Tab Bar */}
                <div style={{ display: "flex", borderBottom: "2px solid #e0e0e0" }}>
                  <div
                    onClick={() => {
                      handleSessionInfoTabSwitch("info");
                    }}
                    style={{
                      flex: "1",
                      padding: "16px",
                      textAlign: "center",
                      borderBottom:
                        activeSessionInfoTab === "info"
                          ? "4px solid #10B981"
                          : "none",
                      color: activeSessionInfoTab === "info" ? "#10B981" : "black",
                      fontWeight: "bold",
                    }}
                  >
                    <BsInfoCircle style={{ fontSize: "1rem" }} />
                  </div>
                  <div
                    onClick={() => {
                      handleSessionInfoTabSwitch("timeline");
                    }}
                    style={{
                      flex: "1",
                      padding: "16px",
                      textAlign: "center",
                      borderBottom:
                        activeSessionInfoTab === "timeline"
                          ? "4px solid #10B981"
                          : "none",
                      color:
                        activeSessionInfoTab === "timeline" ? "#10B981" : "black",
                      fontWeight: "bold",
                    }}
                  >
                    <RiTimelineView style={{ fontSize: "1rem" }} />
                  </div>
                </div>

                {/* Content */}
                {activeSessionInfoTab == "info" ? (
                  <div className="p-4">
                    <div className="d-flex gap-3">
                      <p>
                        <MdOutlineLocationOn />
                      </p>
                      <p className="fw-bold col-md-1">Location:</p>
                      <p>Delhi, India</p>
                    </div>
                    <div className="d-flex gap-3">
                      <p>
                        <RiComputerLine />
                      </p>
                      <p className="fw-bold col-md-1">IP:</p>
                      <p>2401:4900:1c5c:f3a6:c48e:a7:1430:7e7e</p>
                    </div>
                    <div className="d-flex gap-3">
                      <p>
                        <RiComputerLine />
                      </p>
                      <p className="fw-bold col-md-1">Platform:</p>
                      <p>
                        Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36
                        (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <p>
                        <RiComputerLine />
                      </p>
                      <p className="fw-bold col-md-1">Referrer:</p>
                      <p>
                        <a
                          href="https://www.ganjingworld.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://www.ganjingworld.com/
                        </a>
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <p>
                        <FaRegHourglass />
                      </p>
                      <p className="fw-bold col-md-1">Duration:</p>
                      <p>1 second</p>
                    </div>
                  </div>
                ) : (
                  <div className="p-4">
                    <div className="d-flex gap-3">
                      <p
                        className=" p-1"
                        style={{ background: "#D9DBE4", borderRadius: "5px" }}
                      >
                        16:24
                      </p>
                      <p>Chat started</p>
                    </div>
                    <div className="d-flex gap-3">
                      <p
                        className=" p-1"
                        style={{ background: "#D9DBE4", borderRadius: "5px" }}
                      >
                        16:23
                      </p>
                      <p>
                        Visitor navigated to{" "}
                        <a
                          href="https://www.anlook.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Anlook | Whatsapp Campaign Management for businesses
                        </a>
                      </p>
                    </div>
                  </div>
                )}
                    </div>
                  </div>
                </div>

                {/* Copy modal */}
                <div
                  class="modal fade"
                  id="copyModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"

                >
                  <div
                    class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "60%" }}
                  >
                    <div class="modal-content">
                        {/* Header */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ padding: "16px", borderbottom: "1px solid lightgrey" }}
                >
                  <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                    <p
                      className="align-items-center d-flex justify-content-center gap-2"
                      style={{ fontWeight: "bold" }}
                    >
                      Copy Conversation
                    </p>
                  </div>

                  <X
                    style={{ fontSize: "1.5rem" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => setSessionInfoModal(false)}
                  />
                </div>
                <div
                  className="p-4 m-2"
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    height: "100%",
                  }}
                >
                  Conversation started on Monday, January 13 2025, 16:23
                  <br />
                  ================================================================
                  <br />
                  [16:23] Visitor navigated to https://www.anlook.com/
                  <br />
                  [16:23] Customer Support (System Message) : 👋 Hi! How can we
                  help?
                  <br />
                  *I have a question
                  <br />
                  *Tell me more
                  <br />
                  [16:24] V1736765636562795 : I have a question
                  <br />
                </div>
                <div
                  className="p-4 m-2 justify-content-center d-flex cursor-pointer"
                  style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
                  onClick={handleCopyConversation}
                >
                  Copy
                </div>
                

                    </div>
                  </div>
                </div>

                {/* Ban modal */}
                <div
                  class="modal fade"
                  id="banModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"

                >
                  <div
                    class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "60%" }}
                  >
                    <div class="modal-content">
                        {/* Header */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ padding: "16px", borderbottom: "1px solid lightgrey" }}
                >
                  <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                    <p
                      className="align-items-center d-flex justify-content-center gap-2"
                      style={{ fontWeight: "bold" }}
                    >
                      Ban
                    </p>
                  </div>

                  <X
                    style={{ fontSize: "1.5rem" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => setSessionInfoModal(false)}
                  />
                </div>
                
                {/* content */}
                <div style={{ height: "100%" }} className="d-flex flex-column">
                  <p className="p-3 mb-0">
                    Why do you want to ban V1736765636562795 ?
                  </p>
                  <textarea
                    className="mx-3 my-0 p-2"
                    style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
                    rows="5"
                  ></textarea>
                  <div style={{ marginTop: "10px" }} className="m-3">
                    <input
                      type="checkbox"
                      checked={banIpChecked}
                      onChange={handleBanCheckboxChange}
                    />
                    <label style={{ marginLeft: "5px" }}>Ban IP</label>
                  </div>
                </div>
              {/* action button */}
                <button className="btn btn-danger m-3">Ban</button>
                    </div>
                  </div>
                </div>

                {/* AI Feedback Modal */}
                <div
                  class="modal fade"
                  id="feedbackModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"

                >
                  <div
                    class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "60%" }}
                  >
                    <div class="modal-content">
                        {/* Header */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ padding: "16px", borderbottom: "1px solid lightgrey" }}
                >
                  <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                    <p
                      className="align-items-center d-flex justify-content-center gap-2"
                      style={{ fontWeight: "bold" }}
                    >
                      AI Assist Feedback
                    </p>
                  </div>

                  <X
                    style={{ fontSize: "1.5rem" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                
                {/* content */}
                <div
                  style={{ margin: "0 5rem", overflow: "scroll", height: "75vh" }}
                  className="d-flex flex-column align-items-start justify-content-start gap-2 scroll-disable"
                >
                  <div className="d-flex justify-content-start mt-4">
                    <img
                      src={logoImage}
                      style={{
                        width: "5rem",
                        height: "auto",
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                      alt="icon"
                    />
                  </div>
                  <h2>AI Assist Beta Feedback Form</h2>
                  <p className="text-center">
                    Your dedication to tawk.to is highly valued. Your feedback below
                    will guide our improvements for an optimized user experience.{" "}
                  </p>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>Name</label>
                    <input
                      placeholder="Enter name"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>Email Address</label>
                    <input
                      placeholder="Enter email"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>How satisfied are you with AI Assist ?</label>
                    <input
                      placeholder="Enter name"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column "
                    style={{ width: "100%" }}
                  >
                    <label>
                      Could you elaborate on the reasons for your degree of
                      satisfaction ?
                    </label>
                    <textarea
                      placeholder="Enter text"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      rows="2"
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>What should be the next addition or improvement ?</label>
                    <textarea
                      placeholder="Enter text"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      rows="2"
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>Did you encounter any issues/bugs?</label>
                    <textarea
                      placeholder="Enter text"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      rows="2"
                      className="p-2 d-flex"
                    />
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>Attachment</label>
                    <div
                      className=" p-3 text-center text-secondary"
                      style={{
                        border: "1px dashed lightgrey",
                        borderRadius: "5px",
                      }}
                    >
                      Drop your files here or{" "}
                      <span className="text-success">browse</span>
                    </div>
                  </div>
                  <div
                    className="my-2 d-flex flex-column"
                    style={{ width: "100%" }}
                  >
                    <label>
                      Is there anything else you want to share with us ?
                    </label>
                    <textarea
                      placeholder="Enter text"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      rows="2"
                      className="p-2 d-flex"
                    />
                  </div>
                  <button
                    className="btn d-flex text-white text-center justify-content-center my-3"
                    style={{
                      background: "rgb(1, 195, 124)",
                      border: "none",
                      width: "100%",
                    }}
                  >
                    Submit
                  </button>
                  <p
                    style={{ color: "grey", width: "100%" }}
                    className="mb-3 text-center justify-content-center"
                  >
                    Report Abuse
                  </p>
                </div>

                    </div>
                  </div>
                </div>
              </>
            ) : (
              <ContactsTab />
            )}

          </div>
      </div>
  );
};

export default Support;
