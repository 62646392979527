import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import trash from "../../images/trash.svg";
import NoIndex from "../NoIndex";
import Chat_BG from "../../images/chat_BG.png";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import * as XLSX from "xlsx";

const EmailTemplate = ({ userID , DeveloperID , keyId}) => {
  const [template, settemplates] = useState({});
  const [loading, setLoading] = useState();
  const [IsBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);
  const [TemplateName, setTemplateName] = useState();
  const [templateLanguage, settemplateLanguage] = useState();
  const [templatePurpose, settemplatePurpose] = useState();
  const [templateSubject, settemplateSubject] = useState();
  const [templateCategory, settemplateCategory] = useState();
  const [body, setBody] = useState(""); // Holds the body text
  const [bodyVariables, setBodyVariables] = useState([]); // Holds the variable values
  const [variableCount, setVariableCount] = useState(0); // Keeps track of variable indices

  //Api call


  const createTemplateApi = async () => {
    try {
      setLoading(true);

      const data = {
        template: {
          name: TemplateName,
          content: body,
          type: templatePurpose,
          header: templateSubject,
          category: templateCategory,
          provider: "string",
          providerId: "string",
          channel: "Email",
        },
      };

      const response = await fetch(
        "https://openapi.anlook.com/api/Template/v1/addTemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(`${DeveloperID}:${keyId}`),
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // setResponse(responseData);
      if (responseData.responseCode === "00") {
        toast.success(responseData.responseMessage);
      } else {
        toast.error(responseData.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to create template. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  //funtionality
  const bodyInputRef = useRef(null); // Ref for the text area
  const handleDivClick = () => {
    // Programmatically click the hidden file input
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      // Add further processing of the file here
    } else {
      toast.error("Failed to upload the filr");
    }
  };
  const downloadSampleExcel = () => {
    const sampleData = [
      {
        templateName: "Example_sale_Temp",
        templateContent:
          "Hello {{1}} , We Are Starting a Sale from {{2}} to {{3}} , get upto {{4}} off . :-) ",
        templateType: "Marketing & Awareness",
        templateSubject: "Wait is Over",
        templateCategory: "Marketing",
      },
    ];

    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contacts");

    XLSX.writeFile(wb, "sample_templates.xlsx");
  };
  const handleFileUpload = () => {
    if (!file) {
      alert("Please upload an Excel file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      // const formattedData = jsonData.map((item) => ({
      //   userid: userID,
      //   contactNumber: item.contactNumber,
      //   contactEmail: item.contactEmail,
      //   contactName: item.contactName,
      //   gender: item.gender,
      //   tags: item.tags ? item.tags.split(",").map((tag) => tag.trim()) : [],
      // }));
      const formattedData = jsonData.map((item) => ({
        templates: [
          {
            name: item.templateName,
            content: item.templateContent,
            type: item.templateType,
            header: item.templateSubject,
            category: item.templateCategory,
            provider: "string",
            providerId: "string",
            channel: "Email",
          },
        ],
      }));
      console.log(formattedData);
      // Call API to send formattedData
      sendDataToAPI(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const sendDataToAPI = async (data) => {
    try {
      const response = await axios.post(
        "https://openapi.anlook.com/api/Template/v1/uploadTemplates",
        {
          contacts: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(`${DeveloperID}:${keyId}`),
          },
        }
      );
      console.log("Response:", response);
      if (response.status === 200) {
        if (
          response.data.responseCode === "00" &&
          response.data.responseMessage === "0 contacts uploaded successfully"
        ) {
          toast.warning(response.data.responseMessage);
        } else {
          toast.success(response.data.responseMessage);
        }
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Response error:", error.response);
        toast.error(`Error: ${error.response.data}`);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        toast.error("No response received from the server.");
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        toast.error(`Error: ${error.message}`);
      }
    }
  };
  // Handler for adding a new variable
  const addVariable = () => {
    const newVariableIndex = variableCount + 1;
    const newVariablePlaceholder = `{{${newVariableIndex}}}`;

    // Access cursor position using ref
    const inputElement = bodyInputRef.current;
    const cursorPosition = inputElement.selectionStart;

    // Insert the new variable at the cursor position
    const newBody =
      body.slice(0, cursorPosition) +
      newVariablePlaceholder +
      body.slice(cursorPosition);

    setBody(newBody);
    setBodyVariables([...bodyVariables, ""]); // Add a new empty value for the variable
    setVariableCount(newVariableIndex);
  };

  // Handler for updating the variable values
  const updateVariable = (index, value) => {
    const updatedVariables = [...bodyVariables];
    updatedVariables[index] = value;
    setBodyVariables(updatedVariables);
  };

  // Replace variables in the text for preview
  const renderPreview = () => {
    let previewText = body;
    bodyVariables.forEach((value, index) => {
      const placeholder = `{{${index + 1}}}`;
      previewText = previewText.replace(placeholder, value || placeholder);
    });
    return previewText ? previewText : "Enter your message text to view here";
  };
  //style
  const PreviewStyle = {
    border: "0.8rem solid black",
    backgroundImage: `url(${Chat_BG})`,
    backgroundSize: "cover", // Adjusts the image to cover the entire container
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
    backgroundPosition: "center", // Centers the image
    width: "100%", // Example width
    height: "100%", // Example height
  };

  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
      <div className="p-4  " style={{ fontFamily: "Nunito,sans-serif" }}>
        <div className=" bg-white px-4">
          <div className="d-flex justify-content-between">
            {" "}
            <div>
              {" "}
              <p className="CreateCampaign px-4">Create Email Template</p>
            </div>
            <div
              className=" px-5 py-2 my-3 text-3-2 cursor-pointer  blue-button"
              style={{ width: "12rem" }}
              onClick={() => setIsBulk(!IsBulk ? true : false)}
            >
              {IsBulk ? "Single " : "Bulk "}
              Upload
            </div>
          </div>
          {IsBulk ? (
            <div className="w-100 h-50 border rounded-2 p-3">
              <h4>Please Upload an Excel file of the templates</h4>
              <div
                className="with rounded text-center p-5"
                style={{
                  backgroundColor: "rgb(246,248,254)",
                  color: "rgb(75,129,244)",
                  border: "1px solid rgb(75,129,244)",
                  borderStyle: "dashed",
                  cursor: "pointer", // Makes the div look clickable
                }}
                onClick={handleDivClick}
              >
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  className="upload-input visually-hidden"
                  id="fileInput"
                  onChange={handleFileChange}
                />
                Upload CSV file
              </div>
              <div className="d-flex justify-content-between">
                {" "}
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => handleFileUpload()}
                >
                  Upload Templates
                </button>{" "}
                <button
                  className="btn text-primary mt-3"
                  onClick={() => downloadSampleExcel()}
                  style={{ backgroundColor: "rgb(228, 234, 252)" }}
                >
                  Download Sample Sheet
                </button>
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-between">
              {/* Form Section here  */}
              <div className="form col-7 px-4 py-3">
                <Form>
                  <div className=" p-3 border-template">
                    <FormGroup>
                      <Form.Label className="text2 ">Template Name</Form.Label>
                      <FormControl
                        className=""
                        type="text"
                        name="name"
                        placeholder="Enter Template Name"
                        value={TemplateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className="py-1">
                      <Form.Label className="text2 ">
                        Template Language
                      </Form.Label>
                      <Form.Select
                        className=""
                        name="templateLanguage"
                        aria-label="Select Language"
                        value={templateLanguage}
                        onChange={(e) => settemplateLanguage(e.target.value)}
                      >
                        <option value="">Select Language</option>
                        <option value="en_US">English</option>
                        <option value="hi">Hindi</option>
                      </Form.Select>
                    </FormGroup>
                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Category
                      </Form.Label>

                      <Form.Select
                        className=""
                        name="templateCategory"
                        aria-label=""
                        value={templateCategory}
                        onChange={(e) => settemplateCategory(e.target.value)}
                      >
                        <option value="">Select Category</option>
                        <option value="MARKETING">Marketing</option>
                        <option value="AUTHENTICATION">Authentication</option>
                      </Form.Select>
                    </FormGroup>
                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Purpose
                      </Form.Label>

                      <Form.Select
                        className=""
                        value={templatePurpose}
                        onChange={(e) => settemplatePurpose(e.target.value)}
                        name="templatepurpose"
                      >
                        <option value="">Select Purpose</option>
                        <option value="1">Lead Generation</option>
                        <option value="2">Marketing & Awareness</option>
                      </Form.Select>
                    </FormGroup>
                  </div>
                  {/* ###################### Email Subject ##################*/}
                  <div className=" p-3 border-template my-3">
                    {
                      <>
                        <FormGroup>
                          <Form.Label className="text2 mt-1">
                            Email Subject
                          </Form.Label>
                          <FormControl
                            className=""
                            type="text"
                            name="name"
                            placeholder="Enter Subject"
                            value={templateSubject}
                            onChange={(e) => settemplateSubject(e.target.value)}
                          />
                        </FormGroup>
                      </>
                    }
                  </div>
                  {/* ###################### Email Body #######################*/}
                  <div className=" p-3 border-template my-3">
                    <FormGroup>
                      <Form.Label className="text2 ">Email Body</Form.Label>
                      <FormControl
                        className=" mt-3"
                        as="textarea"
                        name="body"
                        ref={bodyInputRef} // Attach the ref here
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder="Type your message here..."
                      />
                      <div
                        className="px-2 my-2"
                        onClick={addVariable}
                        style={{
                          padding: "10px 15px",
                          backgroundColor: "#007BFF",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Add variable
                      </div>
                    </FormGroup>

                    {bodyVariables.map((value, index) => (
                      <>
                        {index === 0 ? (
                          <Form.Label className="text2 ">
                            Variable Inputs
                          </Form.Label>
                        ) : (
                          ""
                        )}
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <label>
                            Variable {index + 1}:{" "}
                            <input
                              type="text"
                              value={value}
                              onChange={(e) =>
                                updateVariable(index, e.target.value)
                              }
                              placeholder={`Enter value for {{${index + 1}}}`}
                              style={{
                                padding: "5px",
                                fontSize: "14px",
                                marginLeft: "10px",
                                width: "60%",
                              }}
                            />
                          </label>
                        </div>
                      </>
                    ))}
                    <hr />
                    <div
                      className="blue-div px-5 py-2 my-3 text-3-2 cursor-pointer  blue-button"
                      style={{ width: "10rem" }}
                      onClick={() => createTemplateApi()}
                    >
                      Submit
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-5  p-4 ">
                <div>
                  <div className="pt-4  align-items-center d-flex  flex-column justify-content-center">
                    <div className="pb-2 text2 text-center">
                      Sample Message Preview
                    </div>
                    <div
                      className=" rounded-3 bg-light w-100 h-100 p-3 text-center"
                      style={PreviewStyle}
                    >
                      <div>{templateSubject}</div>
                      <div>{renderPreview()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailTemplate;
