import React, { useEffect, useRef, useState } from "react";
// import { saveToLocalFile, uploadToServer } from "../utils/fileOperations";
import { saveToFile   } from "../../utils/fileOperations";
import CampaignTracker from "./CampaignTracker";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Track from "../../TrackingPage";
import Logo from "../../images/MY-Anlook.png";
import LogoutImage from "../../images/logout.svg";
import Women from "../../images/My-landing-img1.png";
import Travel from "../../images/travel_tourism_1x.webp";
import Fintech from "../../images/fintech_finances_1x.webp";
import Health from "../../images/health_wellness_1x.webp";
import Education from "../../images/education_ed_tech_2x.webp";
import Food from "../../images/food_restaurant_2x.webp";
import WomenWitheMobile from "../../images/footer_1x.webp";
import blueTick from "../../images/LandingPageImage/blueTick.svg";
import { Link } from "react-router-dom";
import megaphone from "../../images/LandingPageImage/megaphone.svg";
import megaphone1 from "../../images/LandingPageImage/megaphone (1).svg";
import megaphone2 from "../../images/LandingPageImage/megaphone (2).svg";
import megaphone3 from "../../images/LandingPageImage/megaphone (3).svg";
import capcha from "../../images/LandingPageImage/capcha.svg";

import formImage from "../../images/LandingPageImage/FormImg.webp";
import redcross from "../../images/LandingPageImage/redcross.svg";
import dropdown from "../../images/LandingPageImage/dropDown.svg";
import { toast, ToastContainer } from "react-toastify";
import ExternalChatBot from "../Landing_Page/Home_Page/ExternalChatBot";
import Tick from "../../images/GREENTICK.png";
import Cross from "../../images/REDCROSS.png";
import Campaign from "../../images/campaign_analytics_1x.webp";
import Order from "../../images/order_management_1x.webp";
import Team from "../../images/inbox_team_1x.webp";
import BroadCast from "../../images/broadcast_1x.webp";
import CreateSession from "./CreateSession";
function MyLanding({ onLoginClick }) {
  const homeRef = useRef(null);
  const demoVideoRef = useRef(null);
  const featuresRef = useRef(null);
  const industryRef = useRef(null);
  const businessgrowthRef = useRef(null);
  const pricingRef = useRef(null);
  const contactusRef = useRef(null);
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [isAnnual, setIsAnnual] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sessionID, setSessionId] = useState("");
  const [campaignIdHook, setCampaignIdHook] = useState("");
  const [utmSource, setUtmSsource] = useState("");
  const [locationEnabled, setLocationEnabled] = useState(true); // State to handle location availability
  const [modalOpen, setModalOpen] = useState(false); // State to handle modal visibility
  const handleClose = () => setShowModal(false);
  const [anlookFormData, setAnlookFormData] = useState({
    firstName: "",
    lastName: "",
    countryCode: "",
    mobileNo: "",
    emailId: "",
    businessName: "",
    website: "",
    location: "",
    industry: "",
    employeeSize: "",
  });
  const initialStepName = `${window.location.origin}/get#home`;
  const initialTimestamp = new Date().toLocaleString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
    .replace(",", "")
    .replace(/\//g, "-")
    .replace(" ", " ")
    .slice(0, 19);
  const formattedTimestamp = initialTimestamp
    .split(" ")
    .map((part, index) => {
      if (index === 0) {
        // Reorder date to "YYYY-MM-DD"
        const dateParts = part.split("-");
        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      }
      return part; // Return time as is
    })
    .join(" ");

  // Initialize `currentArray` with the `#home` entry
  const [currentArray, setCurrentArray] = useState([
    { stepName: initialStepName, timestamp: formattedTimestamp },
  ]);
  const previousArrayRef = useRef([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [isError, setIsError] = useState(false);
  // Handle form input changes
 
  const handleAnlookInputChange = (e) => {
    setAnlookFormData({
      ...anlookFormData,
      [e.target.name]: e.target.value.trim(), // Remove leading/trailing whitespace
    });
  };
  // Validation function
  const validateForm_freetrial = () => {
    const phonePattern = /^[0-9]{10}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,6}(\/.*)?$/;
    const urlPattern = /(\.com|\.in)$/i;
    if (!phonePattern.test(anlookFormData.mobileNo)) {
      toast.error("Enter a valid 10-digit mobile number.");
      return false;
    }
    if (!emailPattern.test(anlookFormData.emailId)) {
      toast.error("Enter a valid email address.");
      return false;
    }
    if (anlookFormData.website && !urlPattern.test(anlookFormData.website)) {
      toast.error("Enter a valid website URL.");
      return false;
    }
    return true;
  };
  // Handle form submission
  const handleAnlookSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm_freetrial()) return;
    try {
      const response = await axios.post(
        "https://api.anlook.com/api/User/submitFreeTrialPlanForm",
        {
          firstName: anlookFormData.firstName,
          lastName: anlookFormData.lastName,
          countryCode: anlookFormData.countryCode,
          mobileNo: anlookFormData.mobileNo,
          emailId: anlookFormData.emailId,
          businessName: anlookFormData.companyName,
          website: anlookFormData.website,
          location: anlookFormData.location,
          industry: anlookFormData.industry,
          employeeSize: anlookFormData.employeeSize,
          designation: anlookFormData.designation,
        }
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.response[0].message);
        setResponseMessage(response.data.response[0].message);
        setIsError(false);
        setAnlookFormData({
          firstName: "",
          lastName: "",
          countryCode: "",
          mobileNo: "",
          emailId: "",
          businessName: "",
          website: "",
          location: "",
          industry: "",
          employeeSize: "",
          companyName: "",
          designation: "",
        });
      } else {
        setResponseMessage("An error occurred. Please try again.");
        setIsError(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage("An error occurred. Please check your connection.");
      setIsError(true);
    }
  };
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  useEffect(() => {
    let storedSessionID = sessionStorage.getItem("sessionID");
    if (storedSessionID) {
      setSessionId(storedSessionID);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      let sessionIDFromUrl = urlParams.get("sessionID");

      if (sessionIDFromUrl) {
        sessionStorage.setItem("sessionID", sessionIDFromUrl);
        setSessionId(sessionIDFromUrl);
      } else {
        const tempSessionID = generateUUID();
        sessionStorage.setItem("sessionID", tempSessionID);
        setSessionId(tempSessionID);
      }
    }
  }, []);
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let campaignid = urlParams.get("campaignid");
    // If not found, check within the hash fragment
    if (!campaignid) {
      urlParams = new URLSearchParams(window.location.hash.substring(1));
      campaignid = urlParams.get("campaignid");
    }
    // Store in sessionStorage if campaignid is found
    if (campaignid) {
      sessionStorage.setItem("campaignId", campaignid);
    }
    // Set campaignId in state
    setCampaignIdHook(campaignid);
  }, []);
  const trackClick = async (requestBody) => {
    try {
      const response = await axios.post(
        "https://api.goseo.in/api/Seo/trackClickHeatMaps",
        requestBody
      );
      // console.log("Click tracked successfully:", response.data);
      return true;
    } catch (error) {
      console.error("Error tracking click:", error);
      return false;
    }
  };
  // Function to handle button click
  const handleButtonClick_SEO = async (buttonName) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = document.referrer === "" ? "direct" : document.referrer;
    // console.log("Referrer:", referrer);
    const storedTrackingData =
      JSON.parse(sessionStorage.getItem("trackingData")) || {};
    let pageUrl;
    if (buttonName === "Login") {
      pageUrl =
        "https://accounts.bumppy.com/?callback=https://www.anlook.com/get&product_id=1"; // Set to static URL for 'Login'
    } else if (buttonName === "Payment Gateway") {
      pageUrl = "https://payments.bumppy.com"; // Set to static URL for 'Login'
    } else {
      pageUrl = window.location.href; // Keep dynamic for other buttons like 'Get Started'
    }
    const requestBody = {
      // campaignid: urlParams.get("campaignid") || storedTrackingData.campaignid,
      campaignid: campaignIdHook,
      // sessionid: urlParams.get("sessionid"),
      sessionid: sessionID,
      x_cord: "123",
      y_cord: "456",
      element: "Button",
      button_name: buttonName,
      pageurl: pageUrl,
      pagename: "Landing Page",
      product: "Anlook",
      redirection_url: window.location.href,
      utm_source: urlParams.get("utm_source") || storedTrackingData.utm_source,
    };
    const seoTracked = await trackClick(requestBody);

    // Only proceed to login if the SEO tracking was successful
    
    if (seoTracked) {
    
      if (buttonName === "Login") {
    
        login();
    
      } else if (buttonName === "Get Started" || buttonName === "See Pricing") {
    
        scrollToPricing();
    
      }
    }
  };

  // State to handle modal visibility and URL to display

  const [showContentModal, setShowContentModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  // Function to handle link clicks and open the modal
  const handleLinkClick = (url) => (e) => {
    e.preventDefault();
    setIframeUrl(url); // Set the URL for the iframe
    setShowContentModal(true); // Show the modal
  };
  // Function to handle modal close
  const handleContentClose = () => setShowContentModal(false);
  // Function to handle modal close
  const buyPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSwitch = () => {
    setIsAnnual(!isAnnual);
  };
  const [pricingData, setPricingData] = useState({
    title: `Unlock the Best WhatsApp Business API Pricing with Super `,
    title2: "Affordable Plans & Premium Services!",
    plans: [
      {
        name: "Basic Plan",
        price: "₹2,000",
        gst: "+ GST",
        monthlyFee: "₹2,000",
      },
      {
        name: "Standard Plan",
        price: "₹5,000",
        gst: "+ GST",
        monthlyFee: "₹5,000",
      },
      {
        name: "Gold Plan",
        price: "₹8,000",
        gst: "+ GST",
        monthlyFee: "₹8,000",
      },
    ],
    features: [
      {
        name: "Website",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      {
        name: "Application",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      { name: "Chat Automation", values: [true, true, true, true] },
      { name: "Automated Workflows", values: [true, true, true, true] },
      { name: "Roles & Permissions", values: [true, true, true, true] },
      { name: "SEO/SMO", values: [false, "Basic", "Intermediate", "Advanced"] },
      {
        name: "Shared Team Inbox",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      {
        name: "Unlimited Team Members",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
    ],
    footerText: [
      "One Time Cost @5000 + GST",
      "Monthly packages start at ₹2000",
    ],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const updateCarousel = () => {
    if (containerRef.current) {
      const cardWidth =
        containerRef.current.querySelector(".My-Landing-card").offsetWidth;
      containerRef.current.style.transform = `translateX(-${
        currentIndex * cardWidth
      }px)`;
    }
  };
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const handleNextClick = () => {
    const totalCards = containerRef.current.children.length;
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, totalCards - 1));
  };
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);
  useEffect(() => {
    updateCarousel();
  }, [currentIndex, isMobile]);
  // features section
  const [activeFeature, setActiveFeature] = useState("Broadcast Engagement");
  const [isChanging, setIsChanging] = useState(false);
  const features = [
    "Broadcast Engagement",
    "Campaign Analytics",
    "Team Inbox",
    "Order Management",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = features.indexOf(activeFeature);
      const nextIndex = (currentIndex + 1) % features.length;
      setIsChanging(true);
      setTimeout(() => {
        setActiveFeature(features[nextIndex]);
        setIsChanging(false);
      }, 300);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeFeature]);
  const handleButtonClick = (feature) => {
    if (feature !== activeFeature) {
      setIsChanging(true);
      setTimeout(() => {
        setActiveFeature(feature);
        setIsChanging(false);
      }, 300);
    }
  };

  const scrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToContactus = () => {
    if (contactusRef.current) {
      contactusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [campaignData, setCampaignData] = useState({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries; // Get the first entry
        if (entry.isIntersecting) {
          // Get the current URL
          const currentUrl = window.location.href;
          // const basePath = currentUrl.split('?')[0];
          const basePath = currentUrl.split("#")[0].split("?")[0];
          const queryParams = currentUrl.includes("?")
            ? currentUrl.split("?")[1]
            : "";
          // Determine the new hash based on the section in view
          let newHash = "";
          if (entry.target === homeRef.current) {
            newHash = "#home";
          } else if (entry.target === demoVideoRef.current) {
            newHash = "#demoVideo";
          } else if (entry.target === featuresRef.current) {
            newHash = "#features";
          } else if (entry.target === industryRef.current) {
            newHash = "#industry";
          } else if (entry.target === businessgrowthRef.current) {
            newHash = "#businessgrowth";
          } else if (entry.target === contactusRef.current) {
            newHash = "#contactus";
          } else if (entry.target === pricingRef.current) {
            newHash = "#pricing";
          }
          // Set the new URL with the new hash, clearing any previous hash
          if (newHash) {
            const stepName = `${window.location.origin}/get${newHash}`;
            // const timestamp = new Date().toISOString().replace("T", " ").split(".")[0]; 
            // const timestamp = new Date().toLocaleString("en-GB").replace(",", "");
            // const timestamp = new Date().toISOString().slice(0, 19).replace("T", " ");
            const timestamp = new Date().toLocaleString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).replace(",", "").replace(/\//g, "-").replace(" ", " ").slice(0, 19);
           
            const formattedTimestamp = timestamp.split(" ").map((part, index) => {
              if (index === 0) {
                const dateParts = part.split("-");
                return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
              }
              return part; 
            }).join(" ");
            setCurrentArray((prevArray) => [
              ...prevArray,
              { stepName,timestamp: formattedTimestamp },
            ]);
            
   // Update campaignData
  //  setCampaignData((prevData) => {
  //   const updatedData = { ...prevData };
  //   if (!updatedData[campaignIdHook]) {
  //     updatedData[campaignIdHook] = {};
  //   }
  //   if (!updatedData[campaignIdHook][sessionID]) {
  //     updatedData[campaignIdHook][sessionID] = [];
  //   }
  //   updatedData[campaignIdHook][sessionID].push({
  //     hash: `#${newHash}`,
  //     timestamp,
  //   });
  //   saveToFile(updatedData, "campaignData.json");
  //   return updatedData;
  // });
            window.history.replaceState(
              null,
              null,
              `${basePath}${newHash}${queryParams ? "?" + queryParams : ""}`
            );
          }
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
      
    );
    // Observe all relevant sections
    if (homeRef.current) observer.observe(homeRef.current);
    if (demoVideoRef.current) observer.observe(demoVideoRef.current);
    if (featuresRef.current) observer.observe(featuresRef.current);
    if (industryRef.current) observer.observe(industryRef.current);
    if (businessgrowthRef.current) observer.observe(businessgrowthRef.current);
    if (contactusRef.current) observer.observe(contactusRef.current);
    if (pricingRef.current) observer.observe(pricingRef.current);
    // Cleanup observer on component unmount
    return () => {
      if (homeRef.current) observer.unobserve(homeRef.current);
      if (demoVideoRef.current) observer.unobserve(demoVideoRef.current);
      if (featuresRef.current) observer.unobserve(featuresRef.current);
      if (industryRef.current) observer.unobserve(industryRef.current);
      if (businessgrowthRef.current)
        observer.unobserve(businessgrowthRef.current);
      if (contactusRef.current) observer.unobserve(contactusRef.current);
      if (pricingRef.current) observer.unobserve(pricingRef.current);
    };
  }, [campaignIdHook, sessionID]);
  useEffect(() => {
    // Function to call the API
    const callApi = () => {
      if (
        currentArray.length > 0 &&
        sessionID &&
        campaignIdHook &&
        currentArray.some(item => item.stepName && item.timestamp) // Ensure requests contain valid entries
      ) {
        axios.post("https://api.goseo.in/api/Seo/InsertNewCampaignLogging", {
          sessionId: sessionID,
          campaignId: campaignIdHook,
          requests: currentArray,
        });
        // Update previousArrayRef to match currentArray after calling API
        previousArrayRef.current = [...currentArray];
        // console.log("API called with currentArray:", currentArray);
      } else {
        // console.log("Skipping API call due to empty payload");
      }
    };
    // Initial API call on page load if criteria are met
    callApi();
    const intervalId = setInterval(() => {
      if (
        JSON.stringify(currentArray) !== JSON.stringify(previousArrayRef.current)
      ) {
        callApi();
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [currentArray, campaignIdHook, sessionID]);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (
  //       JSON.stringify(currentArray) !== JSON.stringify(previousArrayRef.current) &&
  //       currentArray.length > 0
  //     ) {
       
  //       axios.post("https://api.goseo.in/api/Seo/InsertNewCampaignLogging", {
  //         sessionId :sessionID,
  //         campaignId :campaignIdHook,
  //         requests: currentArray,
  //       });
        
  //       previousArrayRef.current = [...currentArray]; 
        // console.log("API called with currentArray:", currentArray);
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [currentArray]);
  // const login = () => {
  //   const productId = "1";
  //   // const callbackUrl = `${window.location.origin}/get`;
  //   const callbackUrl = "https://www.anlook.com/get";
  //   const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}`;
  //   window.location.href = redirectUrl;
  // };
  // const login = () => {
  //   const productId = "1";
  //    const callbackUrlBase = "https://www.anlook.com/get";
  //   const callbackUrlBase = `${window.location.origin}/get`;
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const campaignid = urlParams.get('campaignid') || '';
  //   const callbackUrl = campaignid
  //     ? `${callbackUrlBase}?campaignid=${campaignid}?sessionID=${sessionID}`
  //     : callbackUrlBase;
  //   const redirectUrl = `https://accounts.bumppy.com/?callback=${encodeURIComponent(callbackUrl)}&product_id=${productId}`;
  //   window.location.href = redirectUrl;
  // };
  const login = () => {
    const productId = "1";
    const callbackUrlBase = `${window.location.origin}/get`;
    const urlParams = new URLSearchParams(window.location.search);
    const campaignid = urlParams.get("campaignid") || "";
    const sessionID = urlParams.get("sessionID") || "";
    const utm_source = urlParams.get("utm_source") || "";
    // const callbackUrl = `${callbackUrlBase}?campaignid=${campaignid}&utm_source=${utm_source}&sessionID=${sessionID}`;
    const callbackUrl = `${callbackUrlBase}`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}`;
    window.location.href = redirectUrl;
  };
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [loginUrl, setLoginUrl] = useState('');
  // const handleLoginModalClose = () => setShowLoginModal(false);
  // const handleLoginModalShow = () => setShowLoginModal(true);
  // const login = () => {
  //   const productId = "1";
  //   const callbackUrlBase = `${window.location.origin}/get`;
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const campaignid = urlParams.get('campaignid') || '';
  //   const sessionID = urlParams.get('sessionID') || '';
  //   const utm_source = urlParams.get('utm_source') || '';

  //   const callbackUrl = `${callbackUrlBase}`;
  //   const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}`;

  //   setLoginUrl(redirectUrl);

  //   handleLoginModalShow();
  // };

  const [sessionData, setSessionData] = useState(null);
  const storeUrlParamsInSessionStorage = () => {
    // Check if tracking data is already present in sessionStorage
    const existingData = JSON.parse(sessionStorage.getItem("trackingData"));
    if (existingData) {
      // console.log( "Tracking data already exists in sessionStorage:",existingData);
      return; // If data exists, skip storing new data
    }
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    // Extract the parameters you want to store
    const sessionData = {
      campaignid: urlParams.get("campaignid"),
      sessionid: urlParams.get("sessionid"),
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
    };
    sessionStorage.setItem("trackingData", JSON.stringify(sessionData));
    // console.log("Data stored in sessionStorage:", sessionData);
  };

  
  const getStateAndCountryFromCoordinates = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=10&addressdetails=1`
      );
      const data = await response.json();
      if (data && data.address) {
        const { state, country } = data.address;
        return { state: state || null, country: country || null };
      } else {
        console.error("No address found for the provided coordinates.");
        return { state: null, country: null };
      }
    } catch (err) {
      console.error("An error occurred while fetching the address:", err);
      return { state: null, country: null };
    }
  };
  const fetchLocation = async () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            resolve({ latitude, longitude });
          },
          (error) => {
            console.error("Error fetching location:", error);
            resolve({ latitude: null, longitude: null }); // If location is denied
          }
        );
      } else {
        // console.log("Geolocation not supported by the browser.");
        resolve({ latitude: null, longitude: null });
      }
    });
  };
  const trackPageView2 = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    // Get the existing tracking data from session storage if available
    const storedTrackingData =
      JSON.parse(sessionStorage.getItem("trackingData")) || {};
    // Function to get the device type
    const getDeviceType = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        return "Mobile";
      } else if (width > 768 && width <= 1024) {
        return "Tablet";
      } else {
        return "Desktop";
      }
    };
    // Build the tracking data object
    const trackingData = {
      // campaignid: urlParams.get("campaignid") || storedTrackingData.campaignid,
      campaignid: campaignIdHook,
      // sessionid: sessionIDFromURL || storedTrackingData.sessionid,
      sessionid: sessionID,
      page_url: window.location.href,
      utm_source: urlParams.get("utm_source") || storedTrackingData.utm_source,
      utm_medium: urlParams.get("utm_medium") || storedTrackingData.utm_medium,
      utm_campaign:
        urlParams.get("utm_campaign") || storedTrackingData.utm_campaign,
      utm_term: urlParams.get("utm_term") || storedTrackingData.utm_term,
      utm_content:
        urlParams.get("utm_content") || storedTrackingData.utm_content,
      referrer: document.referrer === "" ? "direct" : document.referrer,
      user_agent: navigator.userAgent,
      timestamp: new Date().toISOString(),
      product: "Anlook",
      device: getDeviceType(),
      country: "location_not_found",
      state: "location_not_found",
    };
    // Store the tracking data in session storage for later use
    // sessionStorage.setItem("trackingData", JSON.stringify(trackingData));
    // Fetch location (latitude, longitude)
    const { latitude, longitude } = await fetchLocation();
    if (latitude && longitude) {
      const location = await getStateAndCountryFromCoordinates(
        latitude,
        longitude
      );
      trackingData.country = location.country;
      trackingData.state = location.state;
    }
    // Send the tracking data to the API
    fetch(`https://api.goseo.in/api/Seo/insertPageTracking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(trackingData),
    })
      .then((response) => {
        // console.log("Response from server:", response);
        return response.json();
      })
      // .then((data) => console.log("Page view tracked successfully:", data))
      .catch((error) => console.error("Error tracking page view:", error));
  };
  const [accessToken, setAccessToken] = useState("Not Found");
  const [user, setUser] = useState({});
  useEffect(() => {
    // Store URL parameters in session storage
    storeUrlParamsInSessionStorage();

    // If sessionID exists, proceed with tracking and fetching plans
    if (sessionID) {
      trackPageView2();
      fetchSubscriptionPlans();
    }
  }, [sessionID]);

  // useEffect(() => {
  //   const storedData = sessionStorage.getItem("trackingData");

  //   if (storedData) {
  //     setSessionData(JSON.parse(storedData));
  //   }
  // }, []);
  const verifyAccessToken = async (token) => {
    try {
      const response = await fetch("https://api.anlook.com/api/User/ssoLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          product: "anlook",
        }),
      });
      const data = await response.json();
      // Check if the response code is "00" for success
      if (data.responseCode === "00") {
        setUser(data.response); // Store user data in the state
        // console.log("Access Token Verified:", data.response);
      } else {
        console.error("Access Token Invalid:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    }
  };
  useEffect(() => {
    // console.log("Component mounted or URL changed"); // Check if useEffect is firing
    // Get the full URL query string
    const queryString = window.location.search;
    // Replace the second `?` with `&` to make the query string valid
    const fixedQueryString = queryString.replace(/\?([^?]+)$/, "&$1");
    // Use the fixed query string with URLSearchParams
    const params = new URLSearchParams(fixedQueryString);
    const token = params.get("accessToken");
    // console.log("Token from URL:", token); // Debug log for token value
    if (token) {
      setAccessToken(token);
      verifyAccessToken(token);
    } else {
      // console.log("No accessToken found in URL");
    }
  }, []);
  const fetchSubscriptionPlans = async () => {
    const url = "https://api.anlook.com/api/Entitlement/fetchSubscriptionPlans";
    const requestBody = {
      userid: "",
      countryCode: "IN",
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
      },
    };
    try {
      const response = await axios.post(url, requestBody, config);
      setPlans(response.data.data); // Assuming the data is in response.data.data
      // setLoading(false);
    } catch (error) {
      // setError('Error fetching subscription plans');
      // setLoading(false);
      console.error("Error fetching subscription plans:", error);
    }
  };
  const logout = () => {
    setUser(null);
  };
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };
  const buyPlanClickAPI = async (planId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const location = await getUserLocation();
      const requestBody = {
        userid: user.userid,
        planid: planId,
        latitude: location.latitude.toString(),
        longitude: location.longitude.toString(),
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Entitlement/buySubscriptionPlan",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink;
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log("Name:", name);
    // console.log("Phone:", phone);
    // console.log("Message:", message);
    setName("");
    setPhone("");
    setMessage("");
    setShowForm(false);
  };
  const handleCloseClick = () => {
    setShowForm(false);
  };
  const [callBackForm, setCallBackForm] = useState({
    mobile: "",
    email: "",
    name: "",
    message: "",
    source: "",
    callback: "Y",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the input is for the 'mobile' field
    if (name === "mobile") {
      // Remove non-digit characters and limit input to 10 digits
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);

      setCallBackForm({
        ...callBackForm,
        [name]: cleanedValue,
      });
    } else {
      // For other form fields, keep the default behavior
      setCallBackForm({
        ...callBackForm,
        [name]: value,
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!callBackForm.name) {
      newErrors.name = "Name is required";
      toast.error("Name is required");
    } else if (!callBackForm.mobile) {
      newErrors.mobile = "Mobile number is required";
      toast.error("Mobile number is required");
    } else if (!/^\d{10}$/.test(callBackForm.mobile)) {
      newErrors.mobile = "Invalid mobile number";
      toast.error("Invalid mobile number");
    } else if (!callBackForm.message) {
      newErrors.message = "Message is required";
      toast.error("Message is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const callbackAPI = async () => {
    if (!validateForm()) return;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        mobile: callBackForm.mobile,
        email: callBackForm.email,
        name: callBackForm.name,
        message: callBackForm.message,
        source: callBackForm.source,
        callback: callBackForm.callback,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/User/saveLeadMessage",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        // Clear the form after success
        setCallBackForm({
          mobile: "",
          email: "",
          name: "",
          message: "",
          source: "",
          callback: "Y",
        });
      } else {
        toast.error("Failed to edit contact");
      }
    } catch {
      // console.log("");
    }
  };
  const featureContent = {
    "Broadcast Engagement": {
      title: "Broadcast Engagement",
      description: `Evaluate your campaign success with Anlook, which provides the tools and insights to refine your marketing strategies for improved engagement, conversions, and business growth. Monitor performance effortlessly, gain precise insights, and understand what resonates most with your audience. With capabilities to gauge ROI, segment audiences, and enhance content strategies, Anlook transforms your marketing efforts into powerful drivers of success.`,
      image: BroadCast,
    },
    "Campaign Analytics": {
      title: "Campaign Analytics",
      description: `Our platform delivers instant query responses by swiftly assigning each customer inquiry to the right expert, enhancing team collaboration and efficiency. With detailed productivity tracking, you can monitor performance metrics, optimize workflows, and increase conversions. This comprehensive solution streamlines operations and elevates customer service, driving your business towards greater success.`,
      image: Campaign,
    },
    "Team Inbox": {
      title: "Team Inbox",
      description: `Effortlessly manage one-time or recurring campaigns with our platform, customizing and scheduling alerts to match customer preferences. Automatically deliver personalized messages that enhance engagement and drive conversions. With user-friendly features, track campaign performance, refine strategies based on real-time insights, and maintain consistent communication. Empower your marketing efforts with our intuitive system, designed to streamline campaign management and maximize audience impact.`,
      image: Team,
    },
    "Order Management": {
      title: "Order Management",
      description:
        "Transform your sales journey by setting up your WhatsApp storefront today. Offer customers an enhanced shopping experience where they can browse, inquire, and purchase with ease, boosting satisfaction and loyalty. Our comprehensive solutions equip you with powerful tools to manage orders, inventory, and customer interactions, ensuring smooth operations and accelerated growth. Embrace the trend of businesses enhancing customer engagement through personalized service and efficient transactions. ",
      image: Order,
    },
  };


  return (
    <div className="gradient-blur">
      <CampaignTracker />
      <CreateSession/>
      {/* <div>
      <h3>Session Storage Data:</h3>
      <h3>SessionID UseState:{sessionID}</h3>
      <h3>CampaignIdHook UseState:{campaignIdHook}</h3>

      {sessionData ? (
        <pre>{JSON.stringify(sessionData, null, 2)}</pre> // Display data in JSON format
      ) : (
        <p>No data found in sessionStorage.</p>
      )}
    </div> */}
      {/* {sessionID} */}
      {/* <Track/> */}

      {/* <button onClick={() => saveToLocalFile(campaignData)}>
        Save to File
      </button>
      <button onClick={() => uploadToServer(campaignData)}>
        Upload to Server
      </button> */}
      <ExternalChatBot />
      <ToastContainer />
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="lg"
        className="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title className="modal-title-custom">
            {selectedPlan?.plan_name} Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <div className="custom-pricing-card">
            <div className="custom-plan-info">
              <p className="custom-plan-details d-flex align-items-start justify-content-start">
                <span className="custom-plan-name pe-3 single-line-css text-start mt-1 ">
                  {selectedPlan?.plan_name} Plan :
                </span>
                <span className="custom-plan-price text-start">
                  <span>
                    {selectedPlan?.currency === "INR" ? (
                      <>₹ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : selectedPlan?.currency === "USD" ? (
                      <>$ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : (
                      <> {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    )}
                  </span>
                  <span className="ps-1 custom-plan-duration">
                    {selectedPlan?.plan_duration}
                  </span>
                </span>
              </p>
              {selectedPlan?.is_setup_fee_applicable === "Y" ? (
                <>
                  <p className="custom-plan-details ">
                    <span className="custom-plan-name pe-3 single-line-css text-start">
                      Setup Fee :
                    </span>

                    <span className="custom-plan-price">
                      <span>
                        {selectedPlan?.currency === "INR" ? (
                          <>₹ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : selectedPlan?.currency === "USD" ? (
                          <>$ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : (
                          <> {Number(selectedPlan.setup_fee).toFixed(0)}</> // Default case
                        )}

                        {/* {selectedPlan.setup_fee} */}
                      </span>
                      <span className="ps-1 custom-plan-duration ">
                        One-Time Fee
                      </span>
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="custom-total-cost">
              <h4 className="custom-total-cost-heading">Total Price</h4>
              <p className="custom-total-cost-amount mb-0">
                {selectedPlan?.currency === "INR" ? (
                  <>₹ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : selectedPlan?.currency === "USD" ? (
                  <>$ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : (
                  <> {Number(selectedPlan?.total_price).toFixed(0)}</> // Default in case currency is unknown
                )}
              </p>
            </div>
          </div>
          {/* Plan Details Section */}
          {/* <div className="modal-plan-details-section">
      <h4>Plan Details</h4>
      <p><strong>User Seats:</strong> {selectedPlan?.user_seats}</p>
      <p><strong>Conversations per Year:</strong> {selectedPlan?.conversations_per_year}</p>
       <p>{selectedPlan?.description}</p>
    </div> */}
          {/* Feature List Section */}

          <div className="custom-modal-feature-list-section">
            <h4>Features</h4>
            <ul className="custom-modal-feature-list">
              {selectedPlan?.features
                .filter((feature) => feature.status === "Y") // Filter only features with status 'Y'
                .map((feature, index) => (
                  <li key={index} className="custom-modal-feature-item">
                    {feature.feature_name}
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body> 
        <Modal.Footer className="modal-footer-custom">
          {/* <button 
 onClick={async () => {
  await handleButtonClick_SEO('Payment Gateway'); 
  buyPlanClickAPI(selectedPlan.id); 
}}
  className="btn btn-primary btn-custom">
         Get Free Trial
    </button> */}
          {!user || !user.username ? (
            <>
              <button
                onClick={() => {
                  handleButtonClick_SEO("Login");
                }}
                className="buy-button"
              >
                Login for Free Trial →
              </button>
            </>
          ) : (
            <button
              onClick={async () => {
                await handleButtonClick_SEO("Payment Gateway");
                buyPlanClickAPI(selectedPlan.id);
              }}
              className="btn btn-primary btn-custom"
            >
              Get Free Trial
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showContentModal} onHide={handleContentClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Embed the content with an iframe */}
          <iframe
            src={iframeUrl}
            style={{ width: "100%", height: "400px", border: "none" }}
            title="Content Modal"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="blue-button" onClick={handleContentClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* navbar section  */}

      <div className="my-landing-p px-2 px-lg-0 mx-lg-0 py-0 bg-gred-green-nav">
        <section class="My-landing-nav py-0">
          <nav class="  ">
            <section className="navbar My-Landing-engagement-platform py-2 d-flex justify-content-between align-items-center gap-2">
              <img className="My-logo nav-landing-logo" src={Logo} alt="logo" />
              <div className="My-nav-buttons d-flex align-items-center">
                {!user || !user.username ? (
                  <>
                    {/* <button className="Landing-btn" onClick={() => handleButtonClick_SEO('Login')}>
                  Login
                </button> */}
                    {/* <button
                      class="Landing-btn"
                      onClick={() => handleButtonClick_SEO("Get Started")}
                    >
                      Get Started
                    </button> */}
                     <Link
                      to="/get/GetStarted"
                      className=" text-decoration-none Landing-btn"
                      state={{ plan: plans }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Get Started");
                      }}
                    >
                      <div className="text-center"> Get Started</div>
                    </Link>
                  </>
                ) : (
                  <div className="user-info d-flex align-items-center">
                    <div className="user-details">
                      <span className="username">{user.username}</span>
                      <span className="mobile">{user.mobile}</span>
                    </div>
                    <div className="tooltip-container">
                      <button className="logout-btn" onClick={() => logout()}>
                        <img src={LogoutImage} height={20} alt="logout" />
                      </button>
                      <span className="tooltip-text">Logout</span>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </nav>
        </section>
      </div>

      <div className="my-landing-p">
        <section
          className="My-Landing-engagement-platform p-0 py-0 center-align"
          ref={homeRef}
        >
          <div className="row col-12 p-0 m-0">
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
              <div>
                <h1 className="hero-title font-size-1 text-start pt-2">
                  Unlock the Full Potential of Your Business with{" "}
                  <span className="text-success">WhatsApp</span>
                </h1>
              </div>
              <div>
                <div className="hero-image">
                  <img
                    className="img-fluid home-responsive-img-2"
                    src={formImage}
                    alt=""
                  />
                </div>
              </div>
              <div className="row col-12 p-0 m-0 mt-lg-4 mt-3">
                <div className="col-12 col-lg-6">
                  <div className="p-2">
                    <div className="text-3-2 d-flex align-items-center">
                      {" "}
                      <img
                        src={megaphone}
                        alt="feature icon"
                        style={{ height: "2rem" }}
                        className="herosection-feature-icon me-2"
                      />
                      Targeted Campaigns
                    </div>
                    <div className="mt-2">
                      Deliver personalized offers that drive results
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="p-2">
                    <div className="text-3-2 d-flex align-items-center">
                      <img
                        src={megaphone1}
                        style={{ height: "2rem" }}
                        alt="feature icon"
                        className="herosection-feature-icon me-2"
                      />
                      Ready-to-Use Templates
                    </div>
                    <div className="mt-2">
                      Send timely updates and reminders with ease
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="p-2">
                    <div className="text-3-2 d-flex align-items-center">
                      <img
                        src={megaphone2}
                        style={{ height: "2rem" }}
                        alt="feature icon"
                        className="herosection-feature-icon me-2"
                      />
                      24/7 Instant Engagement
                    </div>
                    <div className="mt-2">
                      Keep your customers connected with no-code chatbots
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="p-2">
                    <div className="text-3-2 d-flex align-items-center">
                      <img
                        src={megaphone3}
                        style={{ height: "2rem" }}
                        alt="feature icon"
                        className="herosection-feature-icon me-2"
                      />
                      Product Catalogue
                    </div>
                    <div className="mt-2">
                      Quickly send catalogues to multiple customers, boosting
                      your sales.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-desktop-100 m-0 p-0">
              <div className="anlook-form-container m-2 m-lg-4 h-desktop-100  p-lg-4 p-3">
                <h4>
                  Start your Free Trial with{" "}
                  <span style={{ color: "#00C17C" }}>Anlook</span> Today!
                </h4>
                <form className="mt-4" onSubmit={handleAnlookSubmit}>
                  <div className="p-0 m-0">
                    <div className="text-gray-landing-hero mb-3">
                      Contact Details
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="firstName"
                          value={anlookFormData.firstName}
                          onChange={handleAnlookInputChange}
                          placeholder="First name"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="lastName"
                          value={anlookFormData.lastName}
                          onChange={handleAnlookInputChange}
                          placeholder="Last name"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="tel"
                          className="form-control bg-light"
                          name="mobileNo"
                          value={anlookFormData.mobileNo}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numbers and limit to 10 digits
                            if (/^\d{0,10}$/.test(value)) {
                              handleAnlookInputChange(e);
                            }
                          }}
                          placeholder="Mobile no"
                          maxLength="10" // Limits to 10 characters on the input field
                          required
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <input
                          type="email"
                          className="form-control bg-light"
                          name="emailId" // Updated name
                          value={anlookFormData.emailId}
                          onChange={handleAnlookInputChange}
                          placeholder="Business email"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-0 m-0">
                    <div className="text-gray-landing-hero mb-3">
                      Company Details
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="companyName" // Updated name
                          value={anlookFormData.companyName}
                          onChange={handleAnlookInputChange}
                          placeholder="Company name"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text" // Changed from "url" to "text" to allow flexible formats
                          className="form-control bg-light"
                          name="website"
                          value={anlookFormData.website}
                          onChange={handleAnlookInputChange}
                          placeholder="Website"
                        />
                        {/* {isError && responseMessage && name === 'website' && (
    <small className="text-danger">{responseMessage}</small>
  )} */}
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="location"
                          value={anlookFormData.location}
                          onChange={handleAnlookInputChange}
                          placeholder="Location"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="designation"
                          value={anlookFormData.designation}
                          onChange={handleAnlookInputChange}
                          placeholder="Designation"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control bg-light"
                          name="industry"
                          value={anlookFormData.industry}
                          onChange={handleAnlookInputChange}
                          placeholder="Industry"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <select
                          className="form-control bg-light"
                          name="employeeSize"
                          value={anlookFormData.employeeSize}
                          onChange={handleAnlookInputChange}
                          required
                        >
                          <option value="">Employee Size</option>
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51-200">51-200</option>
                          <option value="201-500">201-500</option>
                          <option value="500+">500+</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* reCAPTCHA Section */}
                  <div className="row d-flex justify-content-center align-items-center mb-4">
                    <div className="col-12 col-lg-6">
                      <div className="d-flex justify-content-start align-items-center bg-light p-2 border-15-only">
                        <input
                          type="checkbox"
                          id="recaptcha"
                          className="custom-checkbox"
                          required
                        />
                        <label
                          htmlFor="recaptcha"
                          className="text-dark-gray mb-0 ml-2"
                          style={{ cursor: "pointer" }} // Ensure the label is clickable
                        >
                          I'm not a robot
                        </label>
                        <img
                          src={capcha}
                          alt="reCAPTCHA"
                          className="recaptcha-image"
                        />
                      </div>
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {/* Any additional content can go here */}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="anlook-submit-button btn w-100"
                  >
                    Start free trial
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* hero section  */}
      <div className="my-landing-p  bg-gred-green-landing">
        <section
          class="My-Landing-engagement-platform py-0 pt-3 center-align" id="home"
          ref={homeRef}
        >
          <div className="row col-12 p-0 m-0 d-flex justify-content-center">
            <div class="col-12 col-lg-6   hero-content ">
              <h1 class="hero-title font-size-1 text-start">
                Take Your Business Marketing to the Next Level with Anlook!
              </h1>
              <p class="hero-description font-size-3 ">
                Supercharge sales with WhatsApp Business API, automating
                marketing, sales, service, and support for personalized customer
                communication
              </p>
              <ul class="feature-list font-size-3 ">
                <li>Broadcast Promotional Offers to Unlimited Users</li>
                <li>Sell your Products on WhatsApp using Catalogs</li>
                <li>Automate Messages via Integrations</li>
                <li>Enable Multi-Agent Live Chat for Customer Support</li>
                <li>Build Chatbots & Solve for 24 x 7 Support & Engagement</li>
              </ul>
              <div class="cta-buttons">
                {/* <button
                  class="Landing-btn"
                  onClick={() => handleButtonClick_SEO("Get Started")}
                >
                </button> */}
                <Link
                      to="/get/GetStarted"
                      className=" text-decoration-none Landing-btn"
                      state={{ plan: plans }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Get Started");
                      }}
                    >
                      <div className="text-center"> Get Started</div>
                    </Link>
                <button
                  class="Landing-btn-2"
                  onClick={() => handleButtonClick_SEO("See Pricing")}
                >
                  See Pricing
                </button>
              </div>
            </div>
            <div class=" col-12 col-lg-6  hero-image ">
              <img
                className="img-fluid home-responsive-img "
                src={Women}
                alt=""
              />
            </div>
          </div>
        </section>
      </div>
      {/* video section */}
      <div className="my-landing-p">
        <section class="My-Landing-engagement-platform" id="demoVideo"  ref={demoVideoRef}>
          <h1 class="My-Landing-main-title font-size-1">
            Advanced WhatsApp Engagement and Conversion Platform
          </h1>
          <div class="My-Landing-platform-content">
            {/* <div class="My-Landing-logo">ANLOCK</div> */}
            {/* <h2 class="My-Landing-subtitle">
              Automate, Engage, Grow your Business with Us
            </h2> */}
            <div class="My-Landing-video-placeholder py-3 px-lg-5 px-0">
              <iframe
                className="custom-video-frame"
                src="https://www.youtube.com/embed/UhWKUcaywqo"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* <div class="My-Landing-play-button"></div> */}
            </div>
            {/* <div class="My-Landing-banner">STAY CONNECTED 24/7 WITH ANLO0K</div> */}
          </div>
        </section>
      </div>
      {/* video section end */}
      {/* features section */}
      <div className="bg-white" id="features" ref={featuresRef}>
        <div className="My-Landing-features ">
          <h1 className="My-Landing-features-title font-size-1">
            Discover Anlook's Power-Packed Features for Unmatched <br />
            Business Growth!
          </h1>
          <div className="main-responsive-flex mb-4 mb-lg-5">
            {features.map((feature) => (
              <button
                key={feature}
                className={`My-Landing-feature-button w-100 ${
                  activeFeature === feature ? "active" : ""
                }`}
                onClick={() => handleButtonClick(feature)}
              >
                {feature}
              </button>
            ))}
          </div>
          <div className="My-Landing-feature-content">
            <img
              src={featureContent[activeFeature].image}
              className={`My-Landing-feature-image-placeholder ${
                isChanging ? "changing" : ""
              }`}
              alt="img"
              // style={{ backgroundColor: featureContent[activeFeature].color }}
            ></img>
            <div
              className={`My-Landing-feature-text ${
                isChanging ? "changing" : ""
              }`}
            >
              <h2>{featureContent[activeFeature].title}</h2>
              <p>{featureContent[activeFeature].description}</p>
              {/* <button
                className="My-Landing-get-started Landing-btn"
                onClick={() => handleButtonClick_SEO("Get Started")}
              >
                Get Started
              </button> */}
               {/* <Link
                      to="/get/GetStarted"
                      className=" text-decoration-none My-Landing-get-started Landing-btn"
                      state={{ plan: plans }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Get Started");
                      }}
                    >
                      <div className="text-center"> Get Started</div>
                    </Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* features section end */}
      {/* Get started section */}
      <div className="my-landing-p2">
        <div className="my-landing-p ">
          <div class="My-Landing-cta-banner">
            <div class="My-Landing-cta-text font-size-1">
              Start optimizing your business with <br /> Anlook today
            </div>
            <div class="main-responsive-flex">
              {/* <button
                class=" Landing-btn"
                onClick={() => handleButtonClick_SEO("Get Started")}
              >
                Get Started
              </button> */}
               <Link
                      to="/get/GetStarted"
                      className=" text-decoration-none Landing-btn"
                      state={{ plan: plans }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Get Started");
                      }}
                    >
                      <div className="text-center"> Get Started</div>
                    </Link>
              <button
                class=" Landing-btn-2"
                onClick={() => handleButtonClick_SEO("See Pricing")}
              >
                See Pricing
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Get started section end */}
      {/* bussiness for all section */}
      <div className="bg-white" id="industry" ref={industryRef}>
        <section className="My-Landing-whatsapp-marketing">
          <h2 className="My-Landing-section-title font-size-1">
            WhatsApp Marketing for Every Business,
            <br />
            Transform Your Growth with Anlook
          </h2>
          <div
            className={`My-Landing-card-carousel ${
              isMobile ? "mobile-view" : ""
            }`}
          >
            <div className="My-Landing-card-container" ref={containerRef}>
              {/* Your existing card components here */}
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Travel}
                  alt="Travel"
                />
                <h3 className="font-size-2">Travel & Tourism</h3>
                <p className="font-size-3">
                  Share travel deals, updates, and guides, keeping travellers
                  informed and engaged.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Fintech}
                  alt="Fintech"
                />
                <h3 className="font-size-2">Fintech Solution</h3>
                <p className="font-size-3">
                  You can send alerts, payment reminders, and tips, improving
                  customer communication and financial management.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Health}
                  alt="Health"
                />
                <h3 className="font-size-2">Health & Wellness</h3>
                <p className="font-size-3">
                  Send appointment reminders, promote health campaigns, and give
                  health tips, improving patient engagement and outcomes.
                </p>
              </div>
              <div className="My-Landing-card">
                <img className="My-Landing-card-image" src={Food} alt="Food" />
                <h3 className="font-size-2">Food & Beverages</h3>
                <p className="font-size-3">
                  Share your menus, discounts, order updates, and event news to
                  make customers happier and more loyal.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Education}
                  alt="Education"
                />
                <h3 className="font-size-2">Ed-Tech</h3>
                <p className="font-size-3">
                  Anlook helps schools inform students and parents about events,
                  deadlines, courses, and academic resources.
                </p>
              </div>
            </div>
          </div>
          <div className="My-Landing-carousel-controls">
            <button
              className="My-Landing-carousel-btn My-Landing-prev"
              onClick={handlePrevClick}
              disabled={currentIndex === 0}
            >
              &lt;
            </button>
            <button
              className="My-Landing-carousel-btn My-Landing-next"
              onClick={handleNextClick}
              disabled={
                currentIndex === containerRef.current?.children.length - 1
              }
            >
              &gt;
            </button>
          </div>
        </section>
      </div>
      {/* Get started section */}
      <div className="my-landing-p2 ">
        <div className="my-landing-p ">
          <div class="My-Landing-cta-banner">
            <div class="My-Landing-cta-text font-size-1">
              Start optimizing your business with <br /> Anlook today
            </div>
            <div class="main-responsive-flex">
              {/* <button
                class=" Landing-btn"
                onClick={() => handleButtonClick_SEO("Get Started")}
              >
                Get Started
              </button> */}
               <Link
                      to="/get/GetStarted"
                      className=" text-decoration-none Landing-btn"
                      state={{ plan: plans }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Get Started");
                      }}
                    >
                      <div className="text-center"> Get Started</div>
                    </Link>
              <button
                class=" Landing-btn-2"
                onClick={() => handleButtonClick_SEO("See Pricing")}
              >
                See Pricing
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
      {/* why choose us section  */}
      <div className="bg-white" id="businessgrowth" ref={businessgrowthRef}>
        <div className="anlook-benefits">
          <h2 className="anlook-benefits__title font-size-1">
            More reasons to choose Anlook
            <br />
            for your Business growth
          </h2>
          <div className="anlook-benefits__grid">
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--megaphone"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Explore Anlook's versatile capabilities for your success.
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Generate high-intent leads with Anlook. Engage customers
                throughout their journey using Anlook's notification engine.
                Keep them updated with automated shipping and delivery updates.
                Track campaign performance to see what worked.
              </p>
            </div>
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--hand"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Increase Sales & Maximize Conversions on WhatsApp
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Transform conversations into conversions with Anlook. Nudge
                nurtured leads through the sales funnel using WhatsApp.
                Personalize upsell and cross-sell offers based on interactions.
                Seamlessly accept payments on WhatsApp with integrated payment
                solutions.
              </p>
            </div>
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--person"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Scale your support with WhatsApp.
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Enhance customer experience and build brand credibility with
                Anlook's 24×7 support. Use one number for all business
                communication and create custom auto-replies for high-volume
                conversations. Monitor response and resolution times to ensure
                top-notch service.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing section ref={pricingRef} */}
      <div className="bg-white">
        <h1 className="My-Landing-pricing-title font-size-1 px-2">
          {pricingData.title}
          <br />
          {pricingData.title2}
        </h1>
        <div className="d-flex  flex-column flex-md-row justify-content-center single-line-css gap-3 mb-4 mb-lg-0">
          <div className="text-center text-md-left">
            <span>
              <img
                src={blueTick}
                alt="blueTick"
                height={10}
                width={10}
                className="me-1"
              />
            </span>
            15-days free trial
          </div>
          <div className="text-center text-md-left">
            <span>
              <img
                src={blueTick}
                alt="blueTick"
                height={10}
                width={10}
                className="me-1"
              />
            </span>
            Cancel anytime
          </div>
          <div className="text-center text-md-left">
            <span>
              <img
                src={blueTick}
                alt="blueTick"
                height={10}
                width={10}
                className="me-1"
              />
            </span>
            No credit card required
          </div>
        </div>
        <div className="pricing-cards-container pt-lg-5 pt-1" ref={pricingRef}>
          {plans?.map((plan) => (
            <div
              className={`pricing-card-wrapper ${
                plan.is_enabled !== "Y" ? "disabled-card" : ""
              }`}
              key={plan.id}
            >
              <div
                className={`pricing-card ${
                  plan.plan_name === "Standard" ? "highlight-card" : ""
                }`}
              >
                <div className="pricing-card-header">
                  <h3>{plan.plan_name}</h3>
                  {plan.plan_name === "Standard" && (
                    <span className="badge">Most Popular</span>
                  )}
                </div>
                <div className="pricing-card-body">
                  <div className="price">
                    {plan?.currency === "INR" ? (
                      <>₹ {Number(plan?.plan_price).toFixed(0)}</>
                    ) : plan?.currency === "USD" ? (
                      <>$ {Number(plan?.plan_price).toFixed(0)}</>
                    ) : (
                      <> {Number(plan?.plan_price).toFixed(0)}</> // Default in case currency is unknown
                    )}
                    <span className="price-duration ps-1">
                      {plan.plan_duration}
                    </span>
                  </div>
                  {plan?.is_setup_fee_applicable === "Y" && (
                    <p className="setup-cost-note">
                      <strong>
                        + One-time setup fee of{" "}
                        {plan?.currency === "INR" ? (
                          <>₹ {Number(plan.setup_fee).toFixed(0)}</>
                        ) : plan?.currency === "USD" ? (
                          <>$ {Number(plan.setup_fee).toFixed(0)}</>
                        ) : (
                          <> {Number(plan.setup_fee).toFixed(0)}</> // Default case
                        )}
                      </strong>
                    </p>
                  )}
                  <p className="description">{plan.plan_desc}</p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/get/BuyPlan"
                      className="buy-button text-decoration-none"
                      state={{ plan, countryCode: "IN" }}
                      onClick={async () => {
                        await handleButtonClick_SEO("Buy Plan");
                      }}
                    >
                      <div className="text-center">{plan.button_text} →</div>
                    </Link>
                  </div>
                  {/* <button
                    onClick={async () => {
                      await handleButtonClick_SEO('Buy Plan'); 
                      buyPlanClick(plan);  
                    }}
                      className="buy-button"
                      disabled={plan.is_enabled !== "Y"}
                    >
                      {plan.button_text} →
                    </button> */}
                  <div className="feature-list pt-3">
                    {plan.features
                      .filter((feature) => feature.status === "Y") // Filter features with status 'Y'
                      .slice(0, showMore ? plan.features.length : 6) // Slice first 8 features
                      .map((feature, index) => (
                        <p key={index} className="feature-item">
                          <img src={blueTick} alt="feature tick" />
                          {feature.feature_name}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center py-5 ">
          <button
            onClick={toggleShowMore}
            className="gray-button bg-white text-gray "
            style={{ border: "1px solid #ccc", borderRadius: "20px" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              {showMore ? "View less details" : "View all Feature details"}
              <span className="ms-1">
                <img src={dropdown} alt="dropdown" />
              </span>
            </div>
          </button>
        </div>
      </div>
      {/* footer section */}
      <footer className="My-Landing-footer">
        <div className="My-Landing-footer-content">
          <p className="My-Landing-copyright">
            © 2024 Anlook. All rights reserved.
          </p>
          <nav className="My-Landing-footer-nav">
            <a
              href="https://www.anlook.com/terms&conditions"
              className="My-Landing-footer-link"
              onClick={handleLinkClick("https://www.anlook.com/terms&conditions")}
            >
              Terms & Conditions
            </a>
            <span className="My-Landing-footer-separator">|</span>
            <a
              href="https://www.anlook.com/PrivacyPolicy"
              className="My-Landing-footer-link"
              onClick={handleLinkClick("https://www.anlook.com/PrivacyPolicy")}
            >
              Privacy Policy
            </a>
          </nav>
        </div>
      </footer>
      {/* Footer section ending */}
    </div>
  );
}
export default MyLanding;
