import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoIndex from "../NoIndex";

import { useRef } from "react";
import Chat_BG from "../../images/chat_BG.png";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import HeaderImage from "../../images/HeaderImage.svg";
import trash from "../../images/trash.svg";

const EditTemplate = ({userID}) => {
  const [templateIdState, setTemplateIdState] = useState(null);
  const [count, setCount] = useState(true);
  const [bodyTextVariables, setBodyTextVariables] = useState([]);
  const [headerTextVariables, setHeaderTextVariables] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [selectedBody, setSelectedBody] = useState(null);
  const [selectedFooter, setSelectedFooter] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState(null);

  const [templates, setTemplates] = useState([]);
  const buttonTypeSelectRef = useRef(null);
  const [includeHeader, setIncludeHeader] = useState(false);
  const [includeFooter, setIncludeFooter] = useState(false);
  const [includeButtons, setIncludeButtons] = useState(false);
  const [template1, setTemplate1] = useState({ body: "" });
  const [variables, setVariables] = useState([]);
  const [template1header, setTemplate1header] = useState({ body: "" });
  const [variablesheader, setVariablesheader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [template, setTemplate] = useState({
    name: "",
    type: "",
    language: "",
    category: "",
    header: "",
    body: "",
    footer: "",
    headerType: "",
    buttonType: "",
    button: "",
    showButton1: "",
    showButton2: "",
    showButton3: "",
    showButton4_quick1: "",
    showButton4_quick2: "",
    showButton4_quick3: "",
    showButton1Value: "",
    showButton2Value: "",
    showButton3Value: "",
    showButton4Value_quick1: "",
    showButton4Value_quick2: "",
    showButton4Value_quick3: "",
    showButton1option: "",
    showButton2option: "",
    showButton3option: "",
    showButton4option_quick1: "",
    showButton4option_quick2: "",
    showButton4option_quick3: "",
    selectButton: "",
  });
  const [response, setResponse] = useState(null);
  const [components, setComponents] = useState([]);
  useEffect(() => {
    const bodyText = variables.map((variable) => variable.value);

    const newComponents = [
      {
        type: "BODY",
        text: template1.body,
        example: {
          body_text: bodyText,
        },
      },
    ];

    if (includeHeader) {
      const headerText = variablesheader.map((variable) => variable.value);
      newComponents.push({
        type: "HEADER",
        format: "TEXT",
        text: template1header.body,
        example: {
          header_text: headerText,
        },
      });
    }

    if (includeFooter) {
      newComponents.push({
        type: "FOOTER",
        text: template.footer,
      });
    }

    if (includeButtons) {
      const buttons = [];
      if (template.showButton4_quick1) {
        buttons.push({
          type: "QUICK_REPLY",
          text: template.showButton4_quick1,
        });
      }
      if (template.showButton4_quick2) {
        buttons.push({
          type: "QUICK_REPLY",
          text: template.showButton4_quick2,
        });
      }
      if (template.showButton4_quick3) {
        buttons.push({
          type: "QUICK_REPLY",
          text: template.showButton4_quick3,
        });
      }

      newComponents.push({
        type: "BUTTONS",
        buttons: buttons,
      });
    }

    setComponents(newComponents);
  }, [
    includeHeader,
    includeFooter,
    includeButtons,
    template1.body,
    variables,
    template1header.body,
    variablesheader,
    template.footer,
    template.showButton4_quick1,
    template.showButton4_quick2,
    template.showButton4_quick3,
  ]);

  const validateTemplate = () => {
    if (!template.name) {
      return "Template Name is required.";
    }

    if (!template.language) {
      return "Template Language is required.";
    }

    if (!template.category) {
      return "Template Category is required.";
    }

    const bodyText = template1.body.trim(); // Trim whitespace from the body text

    const words = bodyText.split(/\s+/);

    const variableWords = words.filter((word) => word.match(/^\{\{.*\}\}$/));

    if (variableWords.length > 0) {
      const nonVariableWords = words.filter(
        (word) => !word.match(/^\{\{.*\}\}$/)
      ).length;

      if (nonVariableWords < 3) {
        return "Body must contain at least three words when variables are added.";
      }
    } else {
      if (bodyText === "") {
        return "Body cannot be empty.";
      }
    }

    if (template.showButton4option_quick1 && !template.showButton4_quick1) {
      return "Button-1 name is required.";
    }
    if (template.showButton4option_quick2 && !template.showButton4_quick2) {
      return "Button-2 name is required.";
    }
    if (template.showButton4option_quick3 && !template.showButton4_quick3) {
      return "Button-3 name is required.";
    }

    return null;
  };

  const editTemplateApi = async () => {
    if (includeHeader && !template1header.body) {
      toast.error("Please fill in the Header field.");
      return;
    }
    if (includeFooter && !template.footer) {
      toast.error("Please fill in the Footer field.");
      return;
    }
    if (
      includeButtons &&
      !template.showButton4option_quick1 &&
      !template.showButton4option_quick2 &&
      !template.showButton4option_quick3
    ) {
      toast.error("Please fill in the Button field.");
      return;
    }
    const validationError = validateTemplate();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    try {
      setLoading(true);

      const data = {
        userid: userID,
        secret: "string",
        templateId: templateIdState,
        name: template.name,
        language: template.language,
        category: template.category,
        components: components,
      };

      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/editTemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setResponse(responseData);
      if (responseData.responseCode === "00") {
        toast.success(responseData.responseMessage);
      } else {
        toast.error(responseData.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to create template. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const handleRemoveButton1 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton1: "",
      showButton1Value: "",
      showButton1option: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton2 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton2: "",
      showButton2Value: "",
      showButton2option: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton3 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton3: "",
      showButton3Value: "",
      showButton3option: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton4_quick1 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick1: "",
      showButton4Value_quick1: "",
      showButton4option_quick1: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton4_quick2 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick2: "",
      showButton4Value_quick2: "",
      showButton4option_quick2: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleRemoveButton4_quick3 = (e) => {
    setTemplate((prevState) => ({
      ...prevState,
      showButton4_quick3: "",
      showButton4Value_quick3: "",
      showButton4option_quick3: "",
      selectButton: ",",
    }));
    buttonTypeSelectRef.current.value = "";
  };
  const handleEditTemplate = () => {
    editTemplateApi();
  };

  const handleCategoryChange = (event) => {
    const templateCategory = event.target.value;
    setTemplate((prevState) => ({
      ...prevState,
      category: templateCategory,
    }));
  };

  const handleLanguageChange = (event) => {
    const templateLanguage = event.target.value;
    setTemplate((prevState) => ({
      ...prevState,
      language: templateLanguage,
    }));
  };
  const [isAddVariableClicked, setIsAddVariableClicked] = useState(false);
  // const extractVariablesFromApiResponse = (apiResponseText) => {
  //   const placeholderRegex = /{{(\d+)}}/g;
  //   const extractedVariables = [];

  //   let match;
  //   while ((match = placeholderRegex.exec(apiResponseText)) !== null) {
  //     const variableLabel = match[0]; // Full placeholder like "{{1}}"
  //     extractedVariables.push({ label: variableLabel, value: "" }); // Initial value is empty
  //   }

  //   return extractedVariables;
  // };
  // const handleInputChange1 = (e, isApiResponse) => {
  //   const { name, value } = e.target;
  //   setTemplate1((prevTemplate) => ({ ...prevTemplate, [name]: value }));

  //   if (isApiResponse) {
  //     const extractedVariables = extractVariablesFromApiResponse(value);

  //     const mergedVariables = [...variables, ...extractedVariables];

  //     setVariables(mergedVariables);
  //   }

  //   if (!isApiResponse) {
  //     const updatedVariables = variables.filter((variable) => {
  //       return value.includes(variable.label);
  //     });
  //     setVariables(updatedVariables);
  //   }
  // };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setTemplate1({ ...template, [name]: value });

    if (name === "type" && value !== "Text") {
      setTemplate1((prevTemplate) => ({ ...prevTemplate, header: "" }));
    }
    const updatedVariables = variables.filter((variable) => {
      return value.includes(variable.label);
    });
    setVariables(updatedVariables);
  };
  const handleInputChange1header = (e) => {
    const { name, value } = e.target;

    setTemplate1header({ ...template1header, [e.target.name]: e.target.value });

    if (name === "type" && value !== "Text") {
      setTemplate1header((prevTemplate) => ({ ...prevTemplate, header: "" }));
    }

    const updatedVariables = variablesheader.filter((variable) => {
      return value.includes(variable.label); // Check if the variable label exists in the new body text
    });
    setVariablesheader(updatedVariables);
  };

  const handleHeaderTypeChange = (e) => {
    const { value } = e.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      headerType: value,
      // Clear header value if headerType is not "Text"
      header: value !== "Text" ? "" : prevTemplate.header,
    }));
  };
  const handleButtonTypeChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton1option: value,
      }));
    }
    if (value === "2") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton2option: value,
      }));
    }
    if (value === "3") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton3option: value,
      }));
    }
    if (value === "4") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton4option_quick1: value,
      }));
    }
    if (value === "5") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton4option_quick2: value,
      }));
    }
    if (value === "6") {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        buttonType: value,
        // Clear header value if headerType is not "Text"
        showButton4option_quick3: value,
      }));
    }
  };

  const handleInputChange = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const addVariableheader = () => {
    if (!isAddVariableClicked) {
      const nextVarNum = variablesheader.length + 1;

      // Ensure consistent formatting by adding curly braces around the variable
      const variableToAdd = ` {{${nextVarNum}}} `;

      setTemplate1header((prevTemplate) => ({
        ...prevTemplate,
        body: (prevTemplate.body || "") + variableToAdd,
      }));

      // Add the variable to the variables array
      setVariablesheader((prevVariables) => [
        ...prevVariables,
        { label: variableToAdd, value: "" },
      ]);

      setIsAddVariableClicked(true);
    }
  };

  const addVariable = () => {
    // Calculate the next variable number
    const nextVarNum = variables.length + 1;

    // Ensure consistent formatting by adding curly braces around the variable
    const variableToAdd = ` {{${nextVarNum}}} `;

    // Insert the variable into the body text
    setTemplate1((prevTemplate) => ({
      ...prevTemplate,
      body: (prevTemplate.body || "") + variableToAdd,
    }));

    // Add the variable to the variables array
    setVariables((prevVariables) => [
      ...prevVariables,
      { label: variableToAdd, value: "" },
    ]);
  };

  const handleVariableValueChangeheader = (index, value) => {
    setVariablesheader((prevVariables) => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index].value = value;
      return updatedVariables;
    });
  };

  const replaceVariablesheader = (body) => {
    return body?.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = variablesheader[variableIndex];
      return variable ? variable.value : ""; // Return variable value if present, otherwise return nothing
    });
  };

  const handleVariableValueChange = (index, value) => {
    setVariables((prevVariables) => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index].value = value;
      return updatedVariables;
    });
  };

  const replaceVariables = (body) => {
    return body?.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = variables[variableIndex];
      return variable ? variable.value : ""; // Return variable value if present, otherwise return nothing
    });
  };
  const handleHeaderToggle = () => {
    setIncludeHeader((prevState) => !prevState);
  };
  const handleFooterToggle = () => {
    setIncludeFooter((prevState) => !prevState);
  };
  const handleButtonToggle = () => {
    setIncludeButtons((prevState) => !prevState);
  };

  const componentArrayJSON = JSON.stringify(components, null, 2);

  const getTemplates = async (templateId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode === "00") {
        const templates = data?.response?.data;
        const specificTemplate = templates.find(
          (template) => template.id === templateId
        );

        if (specificTemplate) {
          setSelectedTemplate(specificTemplate);
          console.log("Found Template:", specificTemplate);
          setTemplateIdState(specificTemplate.id);
          const headerComponent =
            specificTemplate.components.find((c) => c.type === "HEADER") ||
            null;
          const bodyComponent =
            specificTemplate.components.find((c) => c.type === "BODY") || null;
          const footerComponent =
            specificTemplate.components.find((c) => c.type === "FOOTER") ||
            null;
          const buttonsComponent =
            specificTemplate.components.find((c) => c.type === "BUTTONS") ||
            null;

          setSelectedHeader(headerComponent);
          setSelectedBody(bodyComponent);
          setSelectedFooter(footerComponent);
          setSelectedButtons(buttonsComponent);
          setTemplate((prevState) => ({
            ...prevState,
            name: specificTemplate.name,
            type: specificTemplate.category,
            language: specificTemplate.language,
            category: specificTemplate.category,
            header: headerComponent ? headerComponent.text : "",
            body: bodyComponent ? bodyComponent.text : "",
            footer: footerComponent ? footerComponent.text : "",
            headerType: headerComponent ? headerComponent.format : "",
            buttonType: buttonsComponent ? buttonsComponent.format : "",
            button: buttonsComponent
              ? JSON.stringify(buttonsComponent.buttons)
              : "",
          }));
          if (bodyComponent) {
            setTemplate1((prevState) => ({
              ...prevState,
              body: bodyComponent.text,
            }));

            if (bodyComponent.example && bodyComponent.example.body_text) {
              const bodyTextValues = bodyComponent.example.body_text[0];

              if (bodyTextVariables.length === 0) {
                const extractedVariables = bodyComponent.text
                  .match(/{{\d+}}/g)
                  .map((variable, index) => ({
                    label: variable,
                    value: bodyTextValues[index] || "",
                  }));
                setBodyTextVariables(extractedVariables);
                console.log("bodyTextVariables",extractedVariables);

                const newVariables = bodyTextValues
                  .map((value, index) => ({
                    label: `{{${index + 1}}}`,
                    value: value || "", 
                  }))
                  .filter(
                    (newVariable) =>
                      !bodyTextVariables.some(
                        (variable) => variable.label === newVariable.label
                      )
                  );
                setVariables((prevVariables) => [
                  ...prevVariables,
                  ...newVariables,
                ]);
              }
            }
          }
          if (headerComponent) {
            setIncludeHeader(true);
            setTemplate1header((prevState) => ({
              ...prevState,
              body: headerComponent.text,
            }));

            if (
              headerComponent.example &&
              headerComponent.example.header_text
            ) {
              const headerTextValues = headerComponent.example.header_text[0];
              console.log("headerTextValues",headerTextValues);

              
              
              if (headerTextValues.length !== 0) {
                setVariablesheader((prevVariables) => [...prevVariables, { value: headerTextValues, label:  `{{${ 1}}}` }]);
                setIsAddVariableClicked(true);
                // setVariablesheader((prevVariables) => [
                //   ...prevVariables,
                //   ...headerTextValues,
                // ]);
              }
            }
          }
          if (footerComponent) {
            setIncludeFooter(true);
            // setTemplate.(prevState => ({ ...prevState, body: selectedFooter.text }));
          }
          if (buttonsComponent) {
            setIncludeButtons(true);
            if (buttonsComponent.buttons[0]) {
              setTemplate((prevTemplate) => ({
                ...prevTemplate,
                buttonType: "4",
                showButton4option_quick1: "4", // Set buttonType and showButton4option_quick1 together
                showButton4_quick1: buttonsComponent.buttons[0]?.text || "", // Update showButton4_quick1 with the button text, ensuring it's not undefined
              }));
            }

            if (buttonsComponent.buttons[1]) {
              setTemplate((prevTemplate) => ({
                ...prevTemplate,
                buttonType: "5",
                // Clear header value if headerType is not "Text"
                showButton4option_quick2: "5",
                showButton4_quick2: buttonsComponent.buttons[1]?.text || "",
              }));
            }

            if (buttonsComponent.buttons[2]) {
              setTemplate((prevTemplate) => ({
                ...prevTemplate,
                buttonType: "6",
                // Clear header value if headerType is not "Text"
                showButton4option_quick3: "6",
                showButton4_quick3: buttonsComponent.buttons[2]?.text || "",
              }));
            }
          }
        } else {
          console.log("No template found with ID:", templateId);
        }
      } else {
        console.error("Failed to fetch templates");
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  const callApi = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get("templateId");
    if (templateId) {
      console.log("Calling getTemplates with templateId:", templateId);
      getTemplates(templateId);
    } else {
      console.log("useEffect called");
    }
  };
useEffect(()=>{
  callApi();
},[]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const templateId = urlParams.get('templateId');
  //   if (templateId) {
  //     console.log('Calling getTemplates with templateId:', templateId);
  //     getTemplates(templateId);
  //   }
  //   else{
  //   console.log('useEffect called');

  //   }
  // }, []);

  return (
    <>
           <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
     
        {/* <h1>Count:{count}</h1>
        <h1>Body Text Variables:</h1>
        <ul>
          {bodyTextVariables.map((variable, index) => (
            <li key={index}>
              {variable.label} - {variable.value}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h1>Template Editor</h1>
        <h3>Selected Header:</h3>
        <pre>
          {selectedHeader
            ? JSON.stringify(selectedHeader, null, 2)
            : "No Header Component"}
        </pre>
        <h3>Selected Body:</h3>
        <pre>
          {selectedBody
            ? JSON.stringify(selectedBody, null, 2)
            : "No Body Component"}
        </pre>
        <h3>Selected Footer:</h3>
        <pre>
          {selectedFooter
            ? JSON.stringify(selectedFooter, null, 2)
            : "No Footer Component"}
        </pre>
        <h3>Selected Buttons:</h3>
        <pre>
          {selectedButtons
            ? JSON.stringify(selectedButtons, null, 2)
            : "No Buttons Component"}
        </pre>
      </div>
      <div>
        <h1>Selected Template:</h1>
        <pre>{JSON.stringify(selectedTemplate, null, 2)}</pre>
      </div> */}
      <div className="p-4  " style={{ fontFamily: "Nunito,sans-serif" }}>
        <div className=" bg-white px-4">
          
          <div className="d-flex">
          <p className="CreateCampaign px-4">Edit Template</p>
        {/* <button className="btn bg-green-filter" onClick={callApi}>Load Tamplate Data</button> */}
        </div>
          <div className="w-100 d-flex justify-content-between">
            <div className="form col-7 px-4 py-3">
              {loading ? (
                <div className=" m-5 p-5 d-flex justify-content-center">
                  <div className="loader "></div>
                </div>
              ) : (
                <Form>
                  {/* ######################     Template     ##################*/}
                  <div className="bg-green p-3 border-template">
                    <FormGroup>
                      <Form.Label className="text2 ">Template Name</Form.Label>
                      <FormControl
                        className="bg-gray1"
                        type="text"
                        name="name"
                        placeholder="Enter Template Name"
                        value={template.name}
                        onChange={handleInputChange}
                      />
                    </FormGroup>

                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Language
                      </Form.Label>

                      <Form.Select
                        className="bg-gray1"
                        name="templateLanguage"
                        aria-label="Select Language"
                        onChange={handleLanguageChange}
                        value={template.language}
                      >
                        <option value="">Select Language</option>
                        <option value="en_US">English</option>
                      </Form.Select>
                    </FormGroup>

                    <FormGroup className=" py-1">
                      <Form.Label className="text2 ">
                        Template Category
                      </Form.Label>

                      <Form.Select
                        className="bg-gray1"
                        name="templateCategory"
                        aria-label=""
                        onChange={handleCategoryChange}
                        value={template.category}
                      >
                        <option value="">Select Category</option>
                        <option value="MARKETING">Marketing</option>
                      </Form.Select>
                    </FormGroup>
                  </div>
                  {/* ######################      Header     ##################*/}
                  <div className="bg-green p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeHeader}
                          onChange={handleHeaderToggle}
                          id="headerSwitch"
                        />
                      </div>
                      <label
                        className="form-check-label text-3-2 mb-1 mx-2"
                        htmlFor="headerSwitch"
                      >
                        Header (Optional)
                      </label>
                      <label
                        className="form-check-label  mb-1 mx-2 d-flex justify-content-center"
                        htmlFor="headerSwitch"
                      >
                        <span className="nav-text font-bold">
                          <span className="text-danger">*</span>Only 1 variable
                          allowed.
                        </span>
                      </label>
                    </FormGroup>
                    {includeHeader && (
                      <>
                        <FormGroup>
                          <Form.Label className="text2 mt-3">
                            Header Type
                          </Form.Label>
                          <FormControl
                            className="bg-gray1"
                            as="select"
                            name="headerType"
                            value={template.headerType}
                            onChange={handleHeaderTypeChange}
                          >
                            <option value="">Select Header Type</option>
                            <option value="TEXT">Text</option>
                            <option value="Image">Image</option>
                            <option value="Media">Media</option>
                          </FormControl>
                        </FormGroup>
                        {template.headerType === "TEXT" && (
                          <>
                            <FormGroup>
                              <Form.Label className="text2 mt-3">
                                Header
                              </Form.Label>
                              <FormControl
                                className="bg-gray1"
                                as="textarea"
                                name="body"
                                value={template1header.body}
                                onChange={handleInputChange1header}
                              />
                            </FormGroup>
                            <div className=" d-flex justify-content-end">
                              <div
                                className="bg-green-filter px-2 border-template m-2 text-white"
                                onClick={addVariableheader}
                                disabled={isAddVariableClicked}
                                style={{
                                  cursor: isAddVariableClicked
                                    ? "not-allowed"
                                    : "pointer",
                                    fontSize:"1.5rem"
                                }}
                              >
                                +
                              </div>
                            </div>

                            {variablesheader.map((variableheader, index) => (
                              <FormGroup key={index} className="d-flex py-1">
                                <Form.Label className="pe-2">
                                  {variableheader.label}
                                </Form.Label>
                                <FormControl
                                  type="text"
                                  value={variableheader.value}
                                  onChange={(e) =>
                                    handleVariableValueChangeheader(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {/* ###################### BODY #######################*/}

                  <div className="bg-green p-3 border-template my-3">
                    <FormGroup>
                      <Form.Label className="text2 ">Body</Form.Label>
                      <FormControl
                        className="bg-gray1 mt-3"
                        as="textarea"
                        name="body"
                        value={template1.body}
                        onChange={(e) => handleInputChange1(e)}
                      />
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                      <div className="bg-green-filter px-2 border-template m-2 text-white cursor-pointer" onClick={addVariable} style={{fontSize:"1.5rem"}}>
                      +
                      </div>
                    </div>
                    {variables.map((variable, index) => (
                      <FormGroup key={index} className="d-flex py-1">
                        <Form.Label className="pe-2">
                          {variable.label}
                        </Form.Label>
                        <FormControl
                          type="text"
                          value={variable.value}
                          onChange={(e) =>
                            handleVariableValueChange(index, e.target.value)
                          }
                        />
                      </FormGroup>
                    ))}
                  </div>

                  {/* ###################### Footer #######################*/}

                  <div className="bg-green p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeFooter}
                          onChange={handleFooterToggle}
                          id="footerSwitch"
                        />
                      </div>
                      <label
                        className="form-check-label text-3-2 mb-1 mx-2"
                        htmlFor="footerSwitch"
                      >
                        Footer (Optional)
                      </label>
                    </FormGroup>
                    {includeFooter && (
                      <>
                        <FormGroup>
                          <FormControl
                            className="bg-gray1 mt-3"
                            as="textarea"
                            name="footer"
                            value={template.footer}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                  {/* ###################### Button #######################*/}

                  <div className="bg-green p-3 border-template my-3">
                    <FormGroup className="d-flex align-items-center justify-content-start">
                      <div className="form-check form-switch toggle-container ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={includeButtons}
                          onChange={handleButtonToggle}
                          id="buttonSwitch"
                        />
                      </div>
                      <label className="form-check-label text-3-2 mb-1 mx-2 d-flex justify-content-center">
                        <span>Buttons (Optional) </span>
                      </label>
                      <label className="form-check-label  mb-1 mx-2 d-flex justify-content-center">
                        <span className="nav-text font-bold">
                          <span className="text-danger">*</span>Maximum 3
                          buttons allowed.
                        </span>
                      </label>
                    </FormGroup>

                    {includeButtons && (
                      <>
                        <FormGroup>
                          <FormControl
                            className="bg-gray1 mt-3"
                            as="select"
                            name="selectButton"
                            // value={template.buttonType}
                            onChange={handleButtonTypeChange}
                            ref={buttonTypeSelectRef}
                          >
                            <option value="">Select Type</option>

                            {/* <option value="1">Call</option>
                    <option value="2">Visit Website</option>
                    <option value="3">Copy Offer Code</option> */}
                            <option value="4">Quick Reply Button 1</option>
                            <option value="5">Quick Reply Button 2</option>
                            <option value="6">Quick Reply Button 3</option>
                          </FormControl>
                        </FormGroup>
                        {template.showButton1option === "1" && (
                          <>
                            <Form.Label className="text2  mt-3 ">
                              Call Button
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton1}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton1: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-4">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  name="button"
                                  placeholder="Mobile Bumber"
                                  value={template.showButton1Value}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                              <FormGroup className="col-1">
                                <div
                                  className="btn1 text-center cursor-pointer "
                                  onClick={(e) => {
                                    handleRemoveButton1();
                                  }}
                                >
                                   <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton2option === "2" && (
                          <>
                            <Form.Label className="text2  mt-3 ">
                              Visit Website Button
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton2}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton2: e.target.value, // Directly update showButton1 with the input value
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-4">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  name="button"
                                  placeholder="Website Link"
                                  value={template.showButton1Value}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                              <FormGroup className="col-1">
                                <div
                                  className="btn1 text-center cursor-pointer "
                                  onClick={(e) => {
                                    handleRemoveButton2();
                                  }}
                                >
                                    <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton3option === "3" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              Copy Offer Code Button
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-4">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton3}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton3: e.target.value, // Directly update showButton1 with the input value
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  name="button"
                                  placeholder="Value"
                                  value={template.showButton1Value}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                              <FormGroup className="col-1">
                                <div
                                  className="btn1 text-center cursor-pointer "
                                  onClick={(e) => {
                                    handleRemoveButton3();
                                  }}
                                >
                                    <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton4option_quick1 === "4" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              Quick Reply Button 1
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick1}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick1: e.target.value, // Directly update showButton1 with the input value
                                    });
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="col-1">
                                <div
                                  className="bg-green-filter p-2 cursor-pointer text-center border-template  text-white "
                                  onClick={(e) => {
                                    handleRemoveButton4_quick1();
                                  }}
                                >
                                    <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-4"></FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton4option_quick2 === "5" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              Quick Reply Button 2
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick2}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick2: e.target.value, // Directly update showButton1 with the input value
                                    });
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="col-1">
                                <div
                                  className="bg-green-filter p-2 cursor-pointer text-center border-template  text-white "

                                  onClick={(e) => {
                                    handleRemoveButton4_quick2();
                                  }}
                                >
                                     <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-4"></FormGroup>
                            </div>
                          </>
                        )}
                        {template.showButton4option_quick3 === "6" && (
                          <>
                            <Form.Label className="text2 mt-3 ">
                              Quick Reply Button 3
                            </Form.Label>

                            <div className="d-flex justify-content-between">
                              <FormGroup className="col-5">
                                <FormControl
                                  className="bg-gray1 "
                                  type="text"
                                  placeholder="Button Name"
                                  name="button"
                                  value={template.showButton4_quick3}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    setTemplate({
                                      ...template,
                                      showButton4_quick3: e.target.value, // Directly update showButton1 with the input value
                                    });
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="col-1">
                                <div
                                 className="bg-green-filter p-2 cursor-pointer text-center border-template  text-white "

                                  onClick={(e) => {
                                    handleRemoveButton4_quick3();
                                  }}
                                >
                                     <img src={trash} alt="Logo" />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-4"></FormGroup>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className=" d-flex justify-content-center">
                    <div
                      className="btn px-5 py-2 my-3 bg-green-filter text-white text-3-2 cursor-pointer "
                      onClick={handleEditTemplate}
                    >
                      Edit
                    </div>
                  </div>
                </Form>
              )}
            </div>

            <div className="col-5  p-4 ">
              <div className="">
                <div className="pt-4  align-items-center d-flex justify-content-center">
                  <div className="">
                    <div className="pb-2 text2 text-center">
                      Sample Message Preview
                    </div>

                    <div
                      className="p-4  shadow  "
                      style={{
                        width: "18rem",
                        minHeight: "35rem",
                        borderRadius: "30px",
                        border: "10px solid #000",
                        position: "relative",
                        backgroundImage: `url(${Chat_BG})`,
                        backgroundRepeat: "repeat",
                        backgroundPosition: "center",
                        boxSshadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
                        backgroundSize: "100%",
                      }}
                    >
                      <div
                        className="  w-100"
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        <div className="d-flex justify-content-center ">
                          {" "}
                          <div
                            className="mt-2 "
                            style={{
                              backgroundColor: "#000",
                              borderRadius: "30px",
                              width: "6rem",
                            }}
                          >
                            -
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        className="row  p-2 m-3"
                        style={{ position: "absolute", bottom: 0, left: 0 }}
                      >
                        <div className="chat-border">
                          <div className="header-text pt-2 ">
                            {/* {template.header || "Header"} */}
                            {replaceVariablesheader(template1header.body) ||
                              "Header"}
                          </div>

                          {template.headerType === "Image" && (
                            <>
                              <div className="">
                                <img
                                  src={HeaderImage}
                                  alt="Header Img"
                                  height={150}
                                />
                              </div>
                            </>
                          )}
                          <div className="body-text py-1">
                            {/* {template1.body||" Enter the text for your message in the language that you've selected."} */}
                            {replaceVariables(template1.body) ||
                              "Enter the text for your message in the language that you've selected."}
                          </div>

                          <div className="footer-text">
                            {" "}
                            {template.footer || "Footer"}
                          </div>
                          <div className="footer-text text-end pb-2">
                            {" "}
                            05:27 PM
                          </div>
                        </div>
                        <div className="">
                          {template.showButton4_quick1 !== "" && (
                            <>
                              <div className=" showButton bg-white my-2 ">
                                {template.showButton4_quick1}
                              </div>
                            </>
                          )}
                          {template.showButton4_quick2 !== "" && (
                            <>
                              <div className=" showButton my-2 ">
                                {template.showButton4_quick2}
                              </div>
                            </>
                          )}
                          {template.showButton4_quick3 !== "" && (
                            <>
                              <div className=" showButton my-2 ">
                                {template.showButton4_quick3}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplate;
