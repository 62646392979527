import React, { useState, useEffect } from "react";
import NoIndex from "./NoIndex";
import axios from "axios";
const IdentityPage = ({ setUser, setPower }) => {
  const [loading, setLoading] = useState(false);
  const anLookTokenVarifyApi = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("https://api.anlook.com/api/User/ssoLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: accessToken,
          product: "anlook",
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.responseCode === "00") {
          sessionStorage.setItem("user", JSON.stringify(responseData.response));
          setUser(true);
          console.log("responseData:", responseData.response.userid);
          // await verifyCredentials(responseData.response.userid);
          await subscriptionDetailsApi(responseData.response.userid);
          await allowedServicesAPI(responseData.response.userid);
        }
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const allowedServicesAPI = async (userId) => {
    try {
      const requestBody = { userid: userId };
      const response = await axios.post(
        "https://api.anlook.com/api/User/getUserAccess",
        requestBody
      );
      if (response.data.responseCode === "00") {
        sessionStorage.setItem(
          "menuItems",
          JSON.stringify(response.data.response)
        );
      } else {
        console.log("Failed to get user access");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const subscriptionDetailsApi = async (userId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const responseBody = {
        userid: userId,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Entitlement/fetchSubscriptionDetails",
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        sessionStorage.setItem("power", "1");
        // window.location.href = "http://localhost:3000/Dashboard";
         window.location.href = "https://www.anlook.com/Dashboard";
      } else if (response.data.responseCode === "03") {
        // window.location.href = "http://localhost:3000/Profile/Plans";
        window.location.href = "https://www.anlook.com/Profile/Plans";
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    anLookTokenVarifyApi();
  }, []);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <NoIndex />
      {loading ? (
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
<div className=""></div>;
export default IdentityPage;
